import React from "react";

import Login from "./Login";
import Register from "./Register";

class Account extends React.Component{

    constructor(props){
        super(props);

        this.state={
            
        }
    }



    render(){
        return(
            <>
                <div class="page-title">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                            <ul class="breadcrumbs">
                                <li><a href="../index.html">Home</a> </li>
                                <li class="separator"></li>
                                <li>Login/Register</li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container padding-bottom-3x mb-1">
                    <div class="row">
                        <div class="col-md-6">
                            <Login />
                        </div>
                        <div class="col-md-6">
                            <Register/>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}


export default Account;