/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Api from '../../Services/api';
import withRouter from '../../Services/withRouter';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';

class Blogs extends React.Component {


    componentDidMount = async()=>{
        this.getBlogs();
    }


    getBlogs = async()=>{
        var response = await Api.GetRequest({}, 'blogs');
        console.log(response)
        if(response.status == 200){
            this.setState({'blogs' : response.blogs});
        }
        else{

        }
    }

    constructor() {
        super();
        this.state = {
            'blogs' : []
        };
    }


    render() {
        return (
            <>
                

                <div class="container padding-bottom-3x mb-1 my-5 blog-page">
                    <div class="row ">
                        
                        <div class="col-12 order-lg-0">
                            <div class="row">
                                {this.state.blogs.map((blog)=>{
                                    return(
                                        <div class="col-md-4">
                                            <Link to={blog.slug} class="blog-post">
                                                <div class="post-thumb">
                                                    <img class="lazy" src={process.env.REACT_APP_URL + blog.image} alt="Blog Post"/>
                                                </div>
                                                <div class="post-body justify-content-start d-flex align-items-start flex-column">
                                                    <h3 class="post-title">{blog.title}</h3>
                                                    <ul class="post-meta">
                                                        <li><i class="icon-user"></i>Admin</li>
                                                        <li><i class="icon-clock"></i>{moment(blog.created_at).format('lll')}</li>
                                                    </ul>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })

                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(Blogs);