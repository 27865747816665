/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Api from '../../Services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class LatestArrivals extends React.Component {


    componentDidMount = async () => {
        this.getBanners();
        this.getLatestArrivals();        
    }


    getLatestArrivals = async()=>{
        this.setState({'loading' : true})
        var response = await Api.GetRequest({}, 'products/latest-arrivals');
        if(response.status == 200){
            this.setState({'latest_arrivals' : response.latest_arrivals})
        }
        else{
            toast.error("Something went wrong. Please try again later.")
        }
        this.setState({'loading' : false})
    }


    constructor(props) {
        super(props);
        this.state = {
            'banners' : [],
            'latest_arrivals' : [],
            'loading' : false
        };
    }



    getBanners = async()=>{
        var response = await Api.GetRequest({}, 'banners');
        if(response.status == 200){
            this.setState({'banners' : response.banners})
        }
        else{
            toast.error("Something.went wrong. Please try again later.");
        }
    }



    render() {
        return (
            <>


                <div className="slider-area-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="hero-slider">
                                    <OwlCarousel className="owl-theme" loop dots={false} nav={true} items={1}>
                                        
                                        {this.state.banners.map((banner)=>{
                                            return(
                                                <div className="item" key={banner.id} style={{"background": "url('"+process.env.REACT_APP_MEDIA_URL + banner.image +"')"}}>
                                                </div>
                                            )
                                        })}
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {this.state.latest_arrivals.length > 0 &&
                    <div className="deal-of-day-section mt-20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title">
                                        <h2 className="h3">Latest Arrivals</h2>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3">
                                    {this.state.latest_arrivals.map((product, index)=>{
                                        return(
                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6">
                                                    <Link to={`/product/product-details/${product.id}`} className="product-card">
                                                        <div className="product-thumb">
                                                            {/* {product.discount != 0 &&
                                                                <span class="product-badge bg-success">{product.discount} % OFF</span>
                                                            }
                                                            */}
                                                            <img className="lazy" src={process.env.REACT_APP_URL + product.image}  alt="Product"/>
                                                            {product.total_ratings > 0 &&
                                                                <div className='px-2 py-1' style={{'position' : 'absolute', left:'5%', "backgroundColor": "hsla(0, 0%, 100%, .8)", 'justifyContent' : 'center', 'display' : 'flex', 'alignItems'  :'center', flexDirection :'row', 'bottom' : 10}}>
                                                                    <div style={{'color' : '#000000'}}>{product.rating}</div>
                                                                    <i style={{'color' : '#000000'}} class="fa fa-star mx-1" aria-hidden="true"></i>
                                                                    <b style={{'color' : '#000000'}} className='mx-2'>|</b>
                                                                    <div style={{'color' : '#000000'}}>{product.total_ratings}</div>
                                                                </div>
                                                            }
                                                            
                                                        </div>

                                                        <div className="product-card-body">
                                                            <h3 className="product-title">
                                                                <Link to={`/product/product-details/${product.id}`}>{product.name}</Link>
                                                            </h3>
                                                            <h4 className="product-price d-flex justify-content-center align-items-center">
                                                                {product.discount == 0 &&
                                                                    <>
                                                                        ₹ {(product.price - (product.price * product.discount)/100).toFixed(0)}
                                                                    </>
                                                                }
                                                                {product.discount != 0 &&
                                                                    <>
                                                                        <del className="main-price">₹ {product.price}</del>
                                                                        <span className="bg-success mx-2 px-2 discount-price" style={{'color' : '#FFFFFF', fontSize : '15px', borderRadius : 50}}>{product.discount} % OFF</span>
                                                                        <div className="final-price">₹ {(product.price - (product.price * product.discount)/100).toFixed(0)}</div>
                                                                    </>
                                                                }
                                                            </h4>

                                                        </div>

                                                    </Link>
                                                </div>
                                        )
                                    })}
                            </div>

                        </div>
                    </div>
                }
    
                {(this.state.latest_arrivals.length == 0 && this.state.loading == false) &&
                    <div className="col-12">
                        <div className="product-card ">
                            <img src="assets/images/no_product.png" style={{'width' : 'initial'}}/>
                            <div style={{'fontWeight' : 'bold', fontSize : "large"}} className="my-3">No Product Found</div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

export default LatestArrivals;