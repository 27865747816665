import React from 'react';
import * as ReactDOM from 'react-dom';
import Api from '../../Services/api';
import Login from '../auth/Login';
import withRouter from '../../Services/withRouter';
import { Link } from 'react-router-dom';


class Payment extends React.Component{


    componentDidMount = async()=>{
        let status = this.props.params.payment_status
        this.setState({'payment_status' : status});
    }


    constructor(props){
        super(props);
        this.state = {
            payment_status : "false"
        }
    }



    render() {
        return(
            <>  
                
                {this.state.payment_status == "true" &&
                    <div class="container padding-bottom-3x mb-1">
                        <div class="card text-center">
                            <div class="card-body padding-top-2x">
                                <h3 class="card-title text-success">Thank you for your order!</h3>
                                <p class="card-text mt-3">Your order has been placed and will be processed as soon as possible.</p>
                                <p class="card-text">You will be receiving an email shortly with confirmation of your order.</p>
                                <div class="padding-top-1x padding-bottom-1x">
                                    <Link class="btn btn-primary m-4" to="/user/profile"><span><i class="icon-package pr-2"></i> View Order</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                

                {this.state.payment_status == "false" &&
                    <div class="container padding-bottom-3x mb-1">
                        <div class="card text-center">
                            <div class="card-body padding-top-2x">
                                <h3 class="card-title text-danger">Payment Failed!</h3>
                                <p class="card-text mt-3">Something went erong. Please try again later.</p>
                                <div class="padding-top-1x padding-bottom-1x">
                                    <Link class="btn btn-primary m-4" to="/home"><span><i class="icon-package pr-2"></i> Home</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}



export default withRouter(Payment);


