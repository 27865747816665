
import './App.css';

import { useEffect, useState } from 'react';
import {BrowserRouter as Router, Routes, Route, HashRouter} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Api from './Services/api';

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/home/Home';
import Brands from './components/brands/brands';
import Blogs from './components/blogs/blogs';
import ContactUs from './components/ContactUs';
import Products from './components/product/products';
import ProductDetails from './components/product/ProductDetails';
import Cart from './components/cart/cart';
import Account from './components/account/Account';
import CompareProducts from './components/product/CompareProducts';
import Checkout from './components/checkout/Checkout';
import Payment from './components/checkout/Payment';
import LatestArrivals from './components/product/LatestArrivals';
import Populars from './components/product/PopularProducts';
import Profile from './components/profile/profile';
import Invoice from './components/orders/Invoice';
import Faqs from './components/faqs/Faqs';
import Pages from './components/pages/Pages';
import Blog from './components/blogs/blog';

function App() {

  const [cart, setCart] = useState(0)
  const [is_logged_in, setLoggedIn] = useState(false)

  useEffect(() => {
    fetchCart()
    checkUserLogin()
  }, [cart.length, is_logged_in]);


  const fetchCart = async()=>{
    var response = await Api.GetRequest({}, 'cart');
    if(response.status == 200){
      await setCart(response.cart);
    }
    else{
      setCart(0);
    }
  }


  const checkUserLogin = async()=>{
    var response = await Api.GetRequest({}, 'verify-user');
    if(response.status == 200){
      setLoggedIn(true)
    }
    else{
      setLoggedIn(false)
    }
  }


  const setUserLogIn = async(status)=>{
    setLoggedIn(status)
  }



  return (
    <Router>
      <div className="App" id="App">
        <Header cart={cart} is_logged_in={is_logged_in} setUserLogIn={setUserLogIn}/>
        <Routes>
          <Route path="/" exact element={<Home/>}/>
          <Route path="/home" exact element={<Home/>}/>
          <Route path="/latest-arrivals" exact element={<LatestArrivals/>}/>
          <Route path="/populars" exact element={<Populars/>}/>
          <Route path="/brands" exact element={<Brands/>}/>
          <Route path="/blogs" exact element={<Blogs/>}/>
          <Route path="/faqs" exact element={<Faqs/>}/>
          <Route path="/blogs" exact element={<Blogs/>}/>
          <Route path="/blogs/:slug" exact element={<Blog/>}/>
          <Route path="/contact-us" exact element={<ContactUs/>}/>
          <Route path="/products"  element={<Products />}/>
          <Route path="/product/product-details/:id" exact element={<ProductDetails fetchCart={fetchCart}/>}/>
          <Route path="/cart" exact element={<Cart fetchCart={fetchCart} is_logged_in={is_logged_in} setUserLogIn={setUserLogIn} />}/>
          <Route path="/account" exact element={<Account/>}/>
          <Route path="/compare/products" exact element={<CompareProducts/>}/>
          <Route path="/checkout" exact element={<Checkout/>}/>
          <Route path="/payment/:payment_status" exact element={<Payment/>}/>
          <Route path="/user/profile" exact element={<Profile checkUserLogin={checkUserLogin}/>}/>
          <Route path="/order/invoice/:order_id" exact element={<Invoice/>}/>
          <Route path="/:slug" element={<Pages/>} />
        </Routes>
       
        <Footer/> 
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;
