import React, { Component } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import withRouter from '../../Services/withRouter';
import Api from '../../Services/api';
import { Link, createSearchParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';

class Products extends Component{

    formRef = React.createRef()

    componentDidMount = async()=>{
        this.setState({'loading' : true});
        const queryParams = new URLSearchParams(this.props.location.search);

        var search = sessionStorage.getItem("search") || "";

        if(this.props.location.search == search){

        }
        else{
            await sessionStorage.setItem('scrollPosition', 0);
            await sessionStorage.setItem('search', this.props.location.search);
        }

        await this.setState({
            'category' : queryParams.get("category") || "",
            'selected_sort' : queryParams.get("selected_sort") || 0,
            'sub_category' : queryParams.get("sub_category") || "",
            'sizes' : queryParams.get("sizes") ? queryParams.get("sizes").split(',') : [],
            'colors' : queryParams.get("colors") ? queryParams.get("colors").split(',') : []
        });

        if(queryParams.get("discount_min")){
            this.setState({ discount: {
                ...this.state.discount,
                min: queryParams.get("discount_min")
            }})
        }

        if(queryParams.get("discount_max")){
            this.setState({ discount: {
                ...this.state.discount,
                max: queryParams.get("discount_max")
            }})
        }

        if(queryParams.get("price_min")){
            this.setState({ price: {
                ...this.state.price,
                min: queryParams.get("price_min")
            }})
        }

        if(queryParams.get("price_max")){
            this.setState({ price: {
                ...this.state.price,
                max: queryParams.get("price_max")
            }})
        }
        await this.getSubCategories();
        await this.getSizeFilter();
        await this.getColorFilter();
        await this.getPriceFilter();
        await this.getProducts();
        this.setState({'loading' : false});
        document.addEventListener('scrollend', this.trackScrolling);
    }


    getColorFilter = async()=>{
        var params = {
            'category_id' : this.state.category
        }
        var response = await Api.GetRequest(params, 'color-filter');
        if(response.status == 200){
            this.setState({'color_filters' : response.color_filters});
        }
        else{
            
        }
    }


    getSizeFilter = async()=>{
        var params = {
            'category_id' : this.state.category
        }
        var response = await Api.GetRequest(params, 'size-filter');
        if(response.status == 200){
            this.setState({'size_filters' : response.size_filters});
        }
        else{
            
        }
    }

    getPriceFilter = async()=>{
        var params = {
            'category_id' : this.state.category
        }
        var response = await Api.GetRequest(params, 'price-filter');
        if(response.status == 200){
            this.setState({'price_filters' : response.price_filters});
        }
        else{
            
        }
    }


    setScrollPosition = async() =>{
        console.log(sessionStorage.getItem('scrollPosition'))
    }

    componentWillUnmount() {
        document.removeEventListener('scrollend', this.trackScrolling);
    }


    componentDidUpdate =async(prevProps, prevState)=>{
        if(prevProps.params){
            if(prevProps.location.search != this.props.location.search){
                await sessionStorage.setItem('category', this.props.params.category_id);
                await sessionStorage.setItem('scrollPosition', 0);
                const queryParams = new URLSearchParams(this.props.location.search);
                await this.setState({
                    'category' : queryParams.get("category") || "",
                    'sort_by' : queryParams.get("sort_by") || 0,
                    'sub_category' : queryParams.get("sub_category") || "",
                    'sizes' : queryParams.get("sizes") ? queryParams.get("sizes").split(',') : [],
                    'colors' : queryParams.get("colors") ? queryParams.get("colors").split(',') : []
                });

                if(queryParams.get("discount_min")){
                    this.setState({ discount: {
                        ...this.state.discount,
                        min: queryParams.get("discount_min")
                    }})
                }

                if(queryParams.get("discount_max")){
                    this.setState({ discount: {
                        ...this.state.discount,
                        max: queryParams.get("discount_max")
                    }})
                }

                if(queryParams.get("price_min")){
                    this.setState({ price: {
                        ...this.state.price,
                        min: queryParams.get("price_min")
                    }})
                }

                if(queryParams.get("price_max")){
                    this.setState({ price: {
                        ...this.state.price,
                        max: queryParams.get("price_max")
                    }})
                }
                await this.getSubCategories();
                await this.getSizeFilter();
                await this.getColorFilter();
                await this.getPriceFilter();
                await this.getProducts();
                
            }
            else{
                var scrollPosition = await sessionStorage.getItem('scrollPosition') || 0;
                window.scroll({top:scrollPosition, behavior: "smooth"})
            }
        }
        else{
            window.scroll({top:0, behavior: "smooth"})
        }
    }

    trackScrolling = (e) => {
       sessionStorage.setItem('scrollPosition', window.scrollY)
       sessionStorage.setItem('search', this.props.location.search)
    };


    constructor(props){
        super(props);
        this.state={
            'categories': [],
            'products': [],
            'category': "",
            'sub_categories': [],
            'sub_category': "",
            'selected_sort': 0,
            
            'discount_filters' : [
                {'min' : 0,  'max' : 20,   'title' : "0-20%"},
                {'min' : 21, 'max' : 40,   'title' : "20-40%"},
                {'min' : 41, 'max' : 60,   'title' : "40-60%"},
                {'min' : 61, 'max' : 80,   'title' : "60-80%"},
                {'min' : 81, 'max' : 100,  'title' : "80-100%"}
            ],

            'price_filters' : [],
            'color_filters' : [],
            'size_filters' : [],
            
            'discount' : {
                'max' : "",
                'min' : ""
            },
            'price' : {
                'max' : "",
                'min' : ""
            },
            'colors' : [],
            'sizes' : [],
            'cart' : [],
            'show_search' : false,
            'searched_text' : "",
            "searched_products" : [],
            "loading" : false
        }
        
    }


    getCategories = async()=>{
        var response = await Api.GetRequest({}, 'categories');
        if(response.status == 200){
            await this.setState({'categories' : response.categories});     
            if(this.state.category){
                this.getSubCategories()
            } 
            else{

            }      
        }
        else{
            toast.error("Something went wrong. Please try again later.")
        }
    }


    getSubCategories = async()=>{
        var params = {
            'category_id' : this.state.category
        }
        var response = await Api.GetRequest(params, 'sub-categories');
        if(response.status == 200){
            this.setState({'sub_categories' : response.sub_categories});
        }
    }



    getProducts = async () => {
        this.setState({'loading' : true, products : []});
        var params = {
            'sub_category_id': this.state.sub_category,
            'category_id': this.state.category,
            'sort_by' : this.state.selected_sort,
            'discount_min' : this.state.discount.min,
            'discount_max' : this.state.discount.max,
            'price_min' : this.state.price.min,
            'price_max' : this.state.price.max ? this.state.price.max : "",
            'sizes' : this.state.sizes,
            'colors' : this.state.colors
        }
        var response = await Api.GetRequest(params, 'products');
        if (response.status == 200) {
            await this.setState({'products': response.products});
        }
        this.setState({'loading' : false})
    }



    setSubCategory = async(sub_category)=>{
        await this.setState({'sub_category' : sub_category});
    }


    setDiscount = async(index)=>{
        await this.setState({
        'discount' : {
            "min" : this.state.discount_filters[index].min,
            "max" : this.state.discount_filters[index].max
        }});
    }


    setSizes = async(size)=>{
        var sizes =  await this.state.sizes;
        await sizes.push(size);
        await this.setState({'sizes' : sizes});
    }


    setColors = async(color)=>{
        var colors =  await this.state.colors;
        await colors.push(color);
        await this.setState({'colors' : colors});
    }


    resetField = async()=>{
        this.formRef.reset();
        this.setState({
            'products' : [],
            'sub_category': "",
            'selected_sort': 0,
            'show_filters' : false,

            'discount' : {
                'max' : "",
                'min' : ""
            },
            'price' : {
                'max' : "",
                'min' : ""
            },
            'colors' : [],
            'sizes' : [],
            'cart' : [],
            'show_search' : false,
            'searched_text' : "",
            "searched_products" : [],
        }, ()=>{
            this.applyFilters()
        });
    }


    setPrice = async(value)=>{
        value = value.split(" ");
        var price = {
            'min' : value[0],
            'max' : value[1] 
        }

        this.setState({'price' : price})
    }


    setSorting = async(value)=>{
        console.log(value)
        this.setState({'selected_sort' : value},
        ()=>{
            this.applyFilters();
        }   
        );        
    }


    showProductDetails = async(product_id)=>{
        this.props.navigate('/product/product-details/'+product_id);
    }

   

    applyFilters = async()=>{
        this.setState({'show_filters' : false});
        this.props.navigate({
            'pathname': "/products",
            'search': createSearchParams({
                'category': this.state.category,
                'sub_category': this.state.sub_category,
                'selected_sort' : this.state.selected_sort,
                'colors' : this.state.colors.join(','),
                'sizes' : this.state.sizes.join(','),
                'discount_min' : this.state.discount.min,
                'discount_max' : this.state.discount.max,
                'price_min' : this.state.price.min,
                'price_max' : this.state.price.max,
            }).toString()
        })
    }


    


    render(){
        return(
            <>

                <div className="container padding-bottom-3x mb-1 mt-5">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="shop-top-filter-wrapper">
                                <div class="row">
                                    <div class="col-12 gd-text-sm-center">
                                        <div class="sptfl justify-content-between d-flex align-items-center">
                                            <div class="shop-sorting align-items-start flex-column">
                                                <label for="sorting">Sort by:</label>
                                                <select class="form-control" value={this.state.selected_sort} onChange={(e)=>{this.setSorting(e.target.value)}} id="sorting" style={{'width' : 'auto', margin : '0'}}>
                                                    <option value="0"></option>
                                                    <option value="1">Price (highest first)</option>
                                                    <option value="2">Discount</option>
                                                    <option value="3">Price (lowest first)</option>
                                                    <option value="4">New Arrivals</option>
                                                    <option value="5">Ratings</option>
                                                    <option value="6">Popular</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-3">
                        <div class="col-lg-3 order-lg-1">
                            <div class="sidebar-toggle position-left" style={{left:0}} onClick={()=>{this.setState({'show_filters' : true})}}><i class="icon-filter"></i></div>
                            <aside className={`sidebar sidebar-offcanvas position-left ${this.state.show_filters ? 'open' : null}`}>
                                <span class="sidebar-close" onClick={()=>{this.setState({'show_filters' : false})}}><i class="icon-x"></i></span>
                                <form ref={(el) => this.formRef = el}>
                                    {this.state.sub_categories.length > 0 &&
                                        <section class="widget widget-categories card rounded p-4">
                                            <h3 class="widget-title">Sub Categories</h3>
                                            <ul id="category_list" class="category-scroll">
                                                {this.state.sub_categories.map((sub_category) => {
                                                    return(
                                                        <div class="custom-control custom-checkbox" style={{'textAlign' : 'left'}}>
                                                            <input class="custom-control-input option" name="sub_category" type="radio" checked={sub_category.id == this.state.sub_category} onChange={(e)=>{this.setSubCategory(e.target.value)}} value={sub_category.id} id="sub_category_{{sub_category.id}}"/>
                                                            <label class="custom-control-label px-2" for="56L">{sub_category.name}<span class="text-muted"></span></label>
                                                        </div>  
                                                    )
                                                })}
                                            </ul>
                                        </section>
                                    }

                                    <section class="widget widget-categories card rounded p-4">
                                        <h3 class="widget-title">Filter by Discount</h3>
                                        {this.state.discount_filters.map((discount, index)=>{
                                            return(
                                                <div class="custom-control custom-checkbox" style={{'textAlign' : 'left'}}>
                                                    <input class="custom-control-input option" name="discount" checked={this.state.discount.max==discount.max && this.state.discount.min == discount.min}  onChange={(e)=>{this.setDiscount(index)}} type="radio"  id="2Black"/>
                                                    <label class="custom-control-label px-2" for="2Black">{discount.title}<span class="text-muted"></span></label>
                                                </div>     
                                            )
                                        })}                                                
                                    </section>

                                    {this.state.size_filters.length > 0 &&
                                        <section class="widget widget-categories card rounded p-4">
                                            <h3 class="widget-title">Filter by Sizes</h3>
                                            {this.state.size_filters.map((size, index)=>{
                                                return(
                                                    <div class="custom-control custom-checkbox" style={{'textAlign' : 'left'}}>
                                                        <input class="custom-control-input option" name="size" checked={this.state.sizes.includes(size)} onChange={(e)=>{this.setSizes(e.target.value)}} value={size}  type="checkbox" id="2Black"/>
                                                        <label class="custom-control-label px-2" for="2Black">{size}<span class="text-muted"></span></label>
                                                    </div>     
                                                )
                                            })}                                                
                                        </section>
                                    }

                                    {this.state.color_filters.length > 0 &&
                                        <section class="widget widget-categories card rounded p-4">
                                            <h3 class="widget-title">Filter by Colors</h3>
                                            {this.state.color_filters.map((color, index)=>{
                                                return(
                                                    <div class="custom-control custom-checkbox" style={{'textAlign' : 'left'}}>
                                                        <input class="custom-control-input option" name="color" checked={this.state.colors.includes(color)} onChange={(e)=>{this.setColors(e.target.value)}} value={color}  type="checkbox"  id="2Black"/>
                                                        <label class="custom-control-label px-2" for="2Black">{color}<span class="text-muted"></span></label>
                                                    </div>     
                                                )
                                            })}                                                
                                        </section>
                                    }  


                                    {this.state.price_filters.length > 0 &&
                                        <section class="widget widget-categories card rounded p-4">
                                            <h3 class="widget-title">Filter by Price</h3>
                                            {this.state.price_filters.map((price, index)=>{
                                                return(
                                                    <div class="custom-control custom-checkbox" style={{'textAlign' : 'left'}}>
                                                        <input class="custom-control-input option" name="price" checked={this.state.price.min == price.min && this.state.price.max == price.max} onChange={(e)=>{this.setPrice(e.target.value)}} value={price.min+' '+price.max} type="radio"  id="2Black"/>
                                                        <label class="custom-control-label px-2" for="2Black">{price.title}<span class="text-muted"></span></label>
                                                    </div>     
                                                )
                                            })}                                                
                                        </section>
                                    }                                    
                                </form>
                                

                                <section class="widget widget-categories justify-content-between d-flex align-items-center flex-row card rounded p-4">
                                    <button onClick={()=>{this.resetField()}} class="btn btn-md btn-primary" href="javascript:;"><span>Reset</span></button>
                                    <button onClick={()=>{this.applyFilters()}} class="btn btn-md btn-primary" href="javascript:;"><span>Apply</span></button>             
                                </section>
                                
                            </aside>
                        </div>

                        <div className="col-lg-9 order-lg-2">
                            
                            <div className="row g-3" id="main_div">
                                {this.state.products.length > 0 &&
                                    <>
                                        {this.state.products.map((product)=>{
                                            return(
                                                
                                                <div onClick={()=>{this.showProductDetails(product.id)}} id={`product_${product.id}`} key={product.id} className="col-xxl-4 col-md-4 col-6">
                                                    <div className="product-card ">
                                                        <div className="product-thumb">
                                                            {product.quantity > 0 &&
                                                                <img className="lazy" src={process.env.REACT_APP_URL + product.image} alt="Product"/>
                                                            }
                                                            {product.quantity == 0 &&
                                                                <div>
                                                                    <img className="lazy" style={{'filter' : "grayscale(100%)"}} src={process.env.REACT_APP_URL + product.image} alt="Product"/>
                                                                    <div className="py-3" style={{'color':'red', position : 'absolute', top:'50%', textAlign : 'center', backgroundColor : '#FFFFFF', width:'100%'}}> Out Of Stock </div>
                                                                </div>
                                                            }
                                                            {product.total_ratings > 0 &&
                                                                <div className='px-2 py-1' style={{'position' : 'absolute', left:'5%', "backgroundColor": "hsla(0, 0%, 100%, .8)", 'justifyContent' : 'center', 'display' : 'flex', 'alignItems'  :'center', flexDirection :'row', 'bottom' : 10}}>
                                                                    <div style={{'color' : '#000000'}}>{product.rating}</div>
                                                                    <i style={{'color' : '#000000'}} class="fa fa-star mx-1" aria-hidden="true"></i>
                                                                    <b style={{'color' : '#000000'}} className='mx-2'>|</b>
                                                                    <div style={{'color' : '#000000'}}>{product.total_ratings}</div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="product-card-body">
                                                            <h3 className="product-title">
                                                                {product.name}
                                                            </h3>
                                                                
                                                            <h4 className="product-price justify-content-center d-flex align-items-center flex-row">
                                                                {product.discount == 0 &&
                                                                    <div className="main-price">
                                                                        ₹ {(product.price - (product.price * product.discount)/100).toFixed(0)}
                                                                    </div>
                                                                }
                                                                {product.discount != 0 &&
                                                                    <>
                                                                        <del className="main-price">₹ {product.price}</del>
                                                                        <span className="bg-success mx-2 px-2 discount-price" style={{'color' : '#FFFFFF', fontSize : '15px', borderRadius : 50}}>{product.discount} % OFF</span>
                                                                        <div className="final-price">₹ {(product.price - (product.price * product.discount)/100).toFixed(0)}</div>
                                                                    </>
                                                                }       
                                                            </h4>   
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            )
                                        })}
                                    </>
                                }
                               
                                {(this.state.loading == false && this.state.products.length == 0) &&
                                    <div className="col-12">
                                        <div className="product-card ">
                                            <img src="assets/images/no_product.png" style={{'width' : '100%'}}/>
                                            <div style={{'fontWeight' : 'bold', fontSize : "large"}} className="my-3">No Product Found</div>
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }

}


export default  withRouter(Products);