import React from "react";
import Modal from 'react-bootstrap/Modal';
import Api from "../../Services/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Login extends React.Component{


    constructor(props){
        super(props);
        this.state={
            'section' : 1,
            'mobile' : "",
            'otp' : "",
            'mobile_error' : "",
            'otp_error' : "",
            'timer' : ""
        }
    }


    componentDidUpdate = async(prevProps, prevState)=>{
        if(prevProps.show_login != this.props.show_login){
            this.setState({
                'section' : 1,
                'show_login' : this.state.show_login
            });
        }
    }


    sendOTP = async()=>{
        var mobile = this.state.mobile;
        if(mobile.trim().length == 0){
            this.setState({'mobile_error' : "Mobile number is required."});
        }
    
        if(mobile.trim() == "0000000000"){
            this.setState({'mobile_error' : "Please enter valid mobile number."});
        }
    
        var mobileRegx =  new RegExp("^[0-9]{10}$");
        var res = await mobileRegx.test(mobile);
        if(res){
            this.setState({'mobile_error' : ""});
            this.requestOtp(mobile.trim());
            toast.success("OTP send to your mobile number.")
        }
        else{
            this.setState({'mobile_error' : "Please enter valid mobile number."});
        }
        
    }



    requestOtp = async(mobile)=>{
        var data = {
            'mobile' : mobile
        };

        var response = await Api.PostRequest(data, 'send-otp');
        if(response.status == 200){
            this.setState({'section' : 2});
        }
        else{
            this.setState({'mobile_error' : response.message});
        }
    }



    verifyOtp = async()=>{
        var data = {
            'otp' : this.state.otp
        }

        var response = await Api.PostRequest(data, 'login');
        if(response.status == 200){
            await localStorage.setItem('token', response.token);
            await localStorage.setItem('name', response.name);
            await localStorage.setItem('email', response.email);
            toast.success("You have successfully logged in.");
            this.props.handleLogin();
        }
        else{
            this.setState({'otp_error' : response.message})
        }
    }



    render(){
        return(

            <Modal show={this.props.show_login} onHide={()=>{this.handleClose()}}>
                <Modal.Header>
                    <Modal.Title>Login</Modal.Title>
                </Modal.Header>
                <Modal.Body className="justify-content-center d-flex align-items-center flex-column">

                    {this.state.section == 1 &&
                        <>
                            <div className="input-group">
                                <input onChange={(event) => {this.setState({"mobile" : event.target.value})}} className="form-control" type="email" name="email" placeholder="Your mobile no."/>
                                <span className="input-group-addon"><i className="icon-phone"></i></span>
                            </div>
                            <small className="mt-1" style={{'color' : 'red', textAlign:'left', 'width' : '100%'}}>{this.state.mobile_error}</small>

                            <div className="mt-2">
                                <button onClick={()=>{this.sendOTP()}} class="btn btn-md btn-primary" href="javascript:;"><span>Send OTP</span></button>
                            </div>
                        </>
                    }   

                    {this.state.section == 2 &&
                        <>
                            <div className="input-group">
                                <input onChange={(event) => {this.setState({"otp" : event.target.value})}} className="form-control" type="number" name="OTP" placeholder="Enter OTP."/>
                                <span className="input-group-addon"><i className="icon-password"></i></span>
                            </div>

                            <small className="mt-1" style={{'color' : 'red', textAlign:'left', 'width' : '100%'}}>{this.state.otp_error}</small>

                            <div className="justify-content-between d-flex align-items-center mt-2" style={{'width' : '75%'}}>
                                <button class="btn btn-md" href="javascript:;"><span>Resend</span></button>
                                <button onClick={()=>{this.verifyOtp()}} class="btn btn-md btn-primary" href="javascript:;"><span>Verify OTP</span></button>
                            </div>
                        </>
                    }   
                    
                   
                </Modal.Body>
            </Modal>
        )
    }

}



export default Login