/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom';
import Api from '../Services/api';
import Login from './auth/Login';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Offcanvas from "react-bootstrap/Offcanvas";
import Modal from 'react-bootstrap/Modal';
import moment, { months } from 'moment/moment';

class Header extends React.Component {

    componentDidMount = async()=>{
        await this.getCategories();
        await this.getPages();
        // await this.showAppPopup();
    }


    componentDidUpdate = (prevProps)=>{
        if(this.state.cart.length != prevProps.cart.length){
            this.setState({'cart' : prevProps.cart})
        }

        if(this.state.is_logged_in != prevProps.is_logged_in){
            this.setState({'is_logged_in' : prevProps.is_logged_in})
        }
    }



    constructor(props) {
        super(props);
        this.state = {
            'categories' : [],
            'cart' : [],
            'is_logged_in' : false,
            'show_login' : false,
            'show_categories' : false,
            'pages' : [],
            'searched_text' : "",
            "searched_products" : [],
            "show_menu" : false,
            "show_app_popup" : false
        };
    }



    getCategories = async()=>{
        var response = await Api.GetRequest({}, 'categories');
        if(response.status == 200){
            this.setState({'categories' : response.categories});
        }
        else{
            toast.error("Something went wrong. Please try again later.")
        }
    }


    getPages = async()=>{
        var response = await Api.GetRequest({}, 'pages');
        if(response.status == 200){
            this.setState({'pages' : response.pages});
        }
        else{
            toast.error("Something went wrong. Please try again later.")
        }
    }


    handleLogin = async()=>{
        this.setState({'show_login' : false});
        this.props.setUserLogIn(true);
    }


    toggleMore = async()=>{
        this.setState({
            'show_more' : !this.state.show_more,
            'show_menu' : false
        });
    }


    searchProducts = async(searched_text)=>{
        this.setState({'searched_text' : searched_text});
        var params = {
            'searched_text' : searched_text
        }
        var response = await Api.GetRequest(params, 'search-products-home');
        if(response.status == 200){
            this.setState({'searched_products' : response.products});
        }

    }


    closeAppPopup = async()=>{
        await localStorage.setItem('popup_date', moment().format("YYYY-MM-DD"));
        this.setState({'show_app_popup' : false});
    } 



    showAppPopup = async()=>{
        var date = await localStorage.getItem('popup_date') || "";
        if(moment().format('YYYY-MM-DD') != date.toString()){
            this.setState({'show_app_popup' : true})
        }
    }

    render() {
        return (

            <>
                <Login show_login={this.state.show_login} handleLogin={this.handleLogin}></Login>


                <Modal centered show={this.state.show_app_popup} onHide={()=>{this.closeAppPopup()}}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h3 class="mt-3">Welcome To <span class="text-primary">18STORES</span></h3>
                        </Modal.Title>
                    </Modal.Header>
                        <Modal.Body>
                            <div>
                                <div style={{'justifyContent' : 'center', 'display' : 'flex', 'alignItems' : 'center'}}>
                                    <img src="assets/images/welcome.png" style={{'width':'50%'}}/>
                                </div>
                                

                                <div>
                                    <h4>DOWNLOAD OUR APP</h4>

                                    <div className='justify-content-between d-flex align-items-center flex-row'>
                                        <img src="assets/images/apple_store.jpg" style={{'width':'42%'}}/>
                                        <img src="assets/images/google_store.jpg" style={{'width':'47%'}}/>
                                    </div>
                                </div>

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" style={{"transform":"rotate(180deg);margin-bottom:-1px"}}>
                                    <path class="elementor-shape-fill" fill="#4680ff" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z">
                                    </path>
                                    <path class="elementor-shape-fill" fill="#4680ff" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                                    <path class="elementor-shape-fill" fill="#4680ff" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                                </svg>
                            </div>
                        </Modal.Body>
                    <Modal.Footer>
                        {/* <button onClick={()=>{this.closeAppPopup()}} class="btn btn-md btn-primary" href="javascript:;"><span>Close</span></button> */}
                    </Modal.Footer>
                </Modal>

                <Offcanvas
                    style={{ zIndex: "10000", width: "70vw" }}
                    placement={"start"}
                    show={this.state.show_menu}
                    onHide={() => {
                        this.setState({'show_menu' : false})
                    }}
                >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title className='mb-0'>
                                <div className="justify-content-start d-flex align-items-center flex-row" style={{'backgroundColor' : '#FFFFFF!important'}}>
                                    <img src="assets/images/logo.png" alt="LOGO" style={{'width' : '50%'}} />
                                </div>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className="widget-categories mobile-cat">
                                <ul id="category_list">
                                    <li className="has-children justify-content-between d-flex align-items-center">
                                        <Link className="category_search" onClick={()=>{this.setState({'show_menu' : false})}} to="home">Home</Link>
                                    </li>
                                    <li className="has-children justify-content-between d-flex align-items-center">
                                        <Link className="category_search" onClick={()=>{this.setState({'show_menu' : false})}} to="cart">Cart</Link>
                                    </li>
                                   
                                    <li className="has-children justify-content-between d-flex align-items-center" onClick={()=>{this.setState({'show_more' : !this.state.show_more})}}>
                                        <a  href="javascript:;" className="category_search">More</a>
                                    </li>
                                    {this.state.show_more == true &&
                                        <div style={{'paddingLeft' : '10vw'}}>
                                            {this.state.pages.map((page)=>{
                                                return(
                                                    <div className="c-item my-2">
                                                        <Link style={{'textAlign' : 'left'}} onClick={()=>{this.toggleMore()}} className="d-block navi-link text-left justify-content-between d-flex flex-row align-items-center" to={`/${page.slug}`}>
                                                            <div>
                                                                <span style={{'color' : '##505050' , marginLeft : '10px'}}>{page.title}</span>
                                                            </div>
                                                            
                                                            <i style={{'color' : '#999'}} className="icon-chevron-right"></i>
                                                        </Link>
                                                    </div>
                                                )
                                            })}  

                                            <div className="c-item my-2">
                                                <Link style={{'textAlign' : 'left'}} onClick={()=>{this.setState({'show_menu' : false})}} className="d-block navi-link text-left justify-content-between d-flex flex-row align-items-center" to={'faqs'}>
                                                    <div>
                                                        <span style={{'color' : '##505050' , marginLeft : '10px'}}>Faqs</span>
                                                    </div>
                                                    
                                                    <i style={{'color' : '#999'}} className="icon-chevron-right"></i>
                                                </Link>
                                            </div>
                        
                                        </div>
                                    }
                                    
                                    
                                    <li className="has-children justify-content-between d-flex align-items-center" onClick={()=>{this.setState({'show_categories' : !this.state.show_categories})}}>
                                        <a  href="javascript:;" className="category_search">Categories</a>
                                    </li>

                                    
                                    <div style={{'paddingLeft' : '10vw'}}>
                                        {this.state.categories.map((category)=>{
                                            return(
                                                <div className="c-item my-2">
                                                    <Link style={{'textAlign' : 'left'}} onClick={()=>{this.setState({'show_menu' : false})}} className="d-block navi-link text-left justify-content-between d-flex flex-row align-items-center" to={`/products?category=${category.id}`}>
                                                        <div>
                                                            <img className="lazy" style={{'width' : '15%'}} src={process.env.REACT_APP_URL + category.image}/>
                                                            <span style={{'color' : '##505050' , marginLeft : '10px'}}>{category.name}</span>
                                                        </div>
                                                        
                                                        <i style={{'color' : '#999'}} className="icon-chevron-right"></i>
                                                    </Link>
                                                </div>
                                            )
                                        })}  
                                    </div>


                                    
                                                                                                  
                                </ul>
                            </div>
                        </Offcanvas.Body>
                </Offcanvas>


                <header className="site-header navbar-sticky">
                    <div className="topbar">
                        <div className="container">
                            <div className="row">
                                    
                                {/* <div className="col-12">
                                    <p style={{'backgroundColor' : '#f4f1ec', 'fontWeight' : 'bold'}} className='py-3'>Free shipping on order value above Rs.599/-</p>
                                </div> */}

                                <div className="col-lg-12">
                                    <div className="d-flex justify-content-between">

                                        <div className="site-branding">
                                            <Link className="site-logo align-self-center" to="/home">
                                                <img src="assets/images/logo.png" alt="LOGO" />
                                            </Link>
                                        </div>

                                        <div className="search-box-wrap d-none d-lg-block d-flex">
                                            <div className="search-box-inner align-self-center">
                                                <div className="search-box d-flex">
                                                    
                                                    <form className="input-group" id="header_search_form">
                                                        <span className="input-group-btn">
                                                            <button><i className="icon-search"></i></button>
                                                        </span>
                                                        <input className="form-control" type="text" value={this.state.searched_text}  onChange={(e)=>{this.searchProducts(e.target.value)}} name="search" placeholder="Search by product name"/>
                                                        {(this.state.searched_products.length > 0  &&  this.state.searched_text.trim().length > 0) &&
                                                            <div className="search-results" style={{'position' : 'absolute', 'cursor' : 'pointer', 'zIndex': '100', height: '65vh', overflow : 'auto'}}>
                                                                {this.state.searched_products.map((product)=>{
                                                                    return(
                                                                        <>  
                                                                            {product.type == 3 &&
                                                                                <Link className='card' onClick={()=>{this.setState({"searched_products" : []})}} to={`/product/product-details/${product.id}`} style={{'borderRadius' : 0,  border : "1px solid #EDEDED"}}>
                                                                                    <div className='row card-body'>
                                                                                            <div className='col-2'>
                                                                                                <img className="lazy search-product-image" style={{'width' : "70%", 'height' : '75%'}} src={process.env.REACT_APP_URL + product.image} alt="Product"/>
                                                                                            </div>
                                                                                            <div className='col-10 justify-content-start d-flex align-items-baseline flex-column'>
                                                                                                <span>{product.name}</span>
                                                                                            </div>
                                                                                    </div>
                                                                                </Link>
                                                                            }

                                                                            {product.type == 1 &&
                                                                                <Link className='card' onClick={()=>{this.setState({"searched_products" : []})}} to={`/products/${product.id}`} style={{'borderRadius' : 0,  border : "1px solid #EDEDED"}}>
                                                                                    <div className='row card-body'>
                                                                                            <div className='col-2'>
                                                                                                <img className="lazy search-product-image" style={{'width' : "70%", 'height' : '75%'}} src={process.env.REACT_APP_URL + product.image} alt="Product"/>
                                                                                            </div>
                                                                                            <div className='col-10 justify-content-start d-flex align-items-baseline flex-column'>
                                                                                                <span>{product.name}</span>
                                                                                            </div>
                                                                                    </div>
                                                                                </Link>
                                                                            }
                                                                            
                                                                        </>
                                                                        
                                                                    )
                                                                })

                                                                }
                                                            </div>
                                                        }
                                                        
                                                    </form>
                                                </div>
                                            </div>
                                            <span className="d-block d-lg-none close-m-serch"><i className="icon-x"></i></span>
                                        </div>
                            
                                        <div className="toolbar d-flex">

                                            <div className="toolbar-item close-m-serch visible-on-mobile"><a href="#">
                                                    <div>
                                                        <i className="icon-search"></i>
                                                    </div>
                                                </a>
                                            </div>
                                            <div onClick={()=>{this.setState({'show_menu' : true})}} className="toolbar-item visible-on-mobile">
                                                <a href="#">
                                                    <div><i className="icon-menu"></i><span className="text-label">Menu</span></div>
                                                </a>
                                            </div>

                                            <div className="toolbar-item">

                                                {this.state.is_logged_in == true &&
                                                    <Link to="user/profile">
                                                        <div>
                                                            <span className="compare-icon">
                                                                <i className="icon-user"></i>
                                                                
                                                            </span>
                                                            <span className="text-label">Profile</span>
                                                        </div>
                                                    </Link>
                                                }
                                                

                                                {this.state.is_logged_in == false &&
                                                    <a onClick={()=>{this.setState({'show_login' : true})}} href="javascript:;">
                                                        <div>
                                                            <span className="compare-icon">
                                                                <i className="icon-user"></i>
                                                            </span>
                                                            <span className="text-label">Login</span>
                                                        </div>
                                                    </a>
                                                }
                                                
                                            </div>
                                            
                                            <div className="toolbar-item">

                                                {this.state.cart.length > 0 &&
                                                    <>
                                                        <Link to="/cart">
                                                            <div>
                                                                <span className="cart-icon">
                                                                    <i className="icon-shopping-cart"></i>
                                                                    <span className="count-label cart_count">{this.state.cart.length}</span>
                                                                </span>
                                                                <span className="text-label">Cart</span>
                                                            </div>
                                                        </Link>
                                                        
                                                    </>
                                                }
                                                {this.state.cart.length == 0 &&
                                                    <>
                                                        <a href="javascript:;">
                                                            <div>
                                                                <span className="cart-icon">
                                                                    <i className="icon-shopping-cart"></i>
                                                                    <span className="count-label cart_count">{this.state.cart.length}</span>
                                                                </span>
                                                                <span className="text-label">Cart</span>
                                                            </div>
                                                        </a>
                                                        <div className="toolbar-dropdown cart-dropdown widget-cart  cart_view_header" id="header_cart_load">
                                                            Cart empty
                                                        </div>
                                                    </>
                                                }
                                                
                                            </div>

                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                        <div className="navbar">
                            <div className="container">
                                <div className="row g-3 w-100">
                                    <div className="col-lg-3">
                                        <div className="left-category-area">
                                            <div className="category-header">
                                                <h4 onClick={()=>{this.setState({'show_categories' : true})}}><i className="icon-align-justify"></i> Categories</h4>
                                            </div>
                                            {this.state.show_categories &&
                                                <div className="category-list active">
                                                    {this.state.categories.map((category)=>{
                                                        return(
                                                            <div className="c-item">
                                                                <Link style={{'textAlign' : 'left'}} onClick={()=>{this.setState({'show_categories' : false})}} className="d-block navi-link text-left" to={`/products?category=${category.id}`}>
                                                                    <img className="lazy" src={process.env.REACT_APP_URL + category.image}/>
                                                                    <span className="text-gray-dark">{category.name}</span>
                                                                    <i className="icon-chevron-right"></i>
                                                                </Link>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </div>


                                    </div>
                                    <div className="col-lg-9 d-flex justify-content-between">
                                        <div className="nav-inner">
                                            <nav className="site-menu">
                                                <ul>
                                                    <li>
                                                        <NavLink style={({ isActive }) => {return {color: isActive ? "#FF6A00" : ""}}} to="home">Home</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink style={({ isActive }) => {return {color: isActive ? "#FF6A00" : ""}}} to="faqs">Faqs</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink style={({ isActive }) => {return {color: isActive ? "#FF6A00" : ""}}} to="blogs">Blogs</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink style={({ isActive }) => {return {color: isActive ? "#FF6A00" : ""}}} to="contact-us">Contact Us</NavLink>
                                                    </li>
                                                    <li className="t-h-dropdown">
                                                        <a className="main-link" href="#">More <i className="icon-chevron-down"></i></a>
                                                        <div className="t-h-dropdown-menu">
                                                            {this.state.pages.map((page)=>{
                                                                return(
                                                                    <Link className="" to={`/${page.slug}`}><i className="icon-chevron-right pr-2"></i>{page.title}</Link>
                                                                )
                                                            })}
                                                        </div>
                                                    </li>
                                                    
                                                </ul>
                                            </nav>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                   
                </header>
            </>
           
        )
    }
}

export default Header