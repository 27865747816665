import React from 'react';
import * as ReactDOM from 'react-dom';
import Api from '../../Services/api';
import Login from '../auth/Login';
import withRouter from '../../Services/withRouter';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import ReactStars from "react-rating-stars-component";
import { ToastContainer, toast } from 'react-toastify';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Accordion from 'react-bootstrap/Accordion';

class Invoice extends React.Component{


    componentDidMount = async()=>{
        let order_id = await this.props.params.order_id
        if(order_id){
            this.getOrderDetails(order_id);
            this.getUser();
        }
        
    }


    

    constructor(props){
        super(props);
        this.state = {
            'order' : {
                'booking_items' : [],
                'total_price' : 0,
                'total_discount' : 0,
                'coupon_discount' : 0,
                "shipping_charge" : 0
            },
            'order_tracks' : [],
            'show_review' : false,
            'id' : "",
            'rating' : 0,
            'review' : "",
            "product_id" : "",
            "review_rating_images" : [],
            "new_uploaded_files" : [],
            "preview_files" : [],
            "old_deleted_images" : [],
            "name" : ""
        }
    }



    uploadImages = async(event)=>{
        // URL.createObjectURL(file)
        var new_uploaded_files = await this.state.new_uploaded_files;
        var preview_files = this.state.preview_files;
        for(const file of event.target.files){
            await new_uploaded_files.push(file)
            await preview_files.push(URL.createObjectURL(file))
        }
        this.setState({
            "new_uploaded_files" : new_uploaded_files,
            "preview_files" : preview_files,
        });
    }



  





    getOrderDetails = async(order_id)=>{
        var params = {
            'order_id' : order_id
        }
        var response = await Api.GetRequest(params, 'order-details');
        if(response.status == 200){
            this.setState({
                'order' : response.order,
                'order_tracks' : response.order_tracks
            })
        }
    }


    deleteFromNewImage = async(index)=>{
        var new_uploaded_files = this.state.new_uploaded_files;
        var preview_files = this.state.preview_files;

        new_uploaded_files.splice(index, 1);
        preview_files.splice(index, 1);

        this.setState({
            'new_uploaded_files' : new_uploaded_files,
            'preview_files' : preview_files
        })
    }


    submitReview = async(event)=>{
        await event.preventDefault();
        var form_data = new FormData();
        form_data.append('product_id', this.state.product_id);
        form_data.append('rating', this.state.rating);
        form_data.append('review', this.state.review);
        form_data.append('id', this.state.id);
        for(const image of this.state.new_uploaded_files){
            form_data.append('review_images', image)
        }

        if(this.state.old_deleted_images.length > 0){
            for(const id of this.state.old_deleted_images){
                form_data.append('old_deleted_images', id)
            }
        }
       

        var response = await Api.PostRequestForm(form_data, 'review-and-rating');
        if(response.status == 200){
            this.setState({'show_review' : false});
            toast.success("Thanks for your valuable feedback.");   
        }
        else{
            toast.error("Something went wrong. Please try again later.");  
        }
    }

    deleteFromOldImage = async(index, id) =>{
        var images = this.state.review_rating_images;
        var old_deleted_images = this.state.old_deleted_images;
        images.splice(index, 1);
        old_deleted_images.push(id)
        this.setState({
            'review_rating_images' : images,
            'old_deleted_images' : old_deleted_images
        });
    }



    checkProfile = async(product_id)=>{
        var name = await localStorage.getItem('name') || "";
        if(name){
            await this.setState({'name' : name});
            await this.openReview(product_id);
        }
        else{
            this.setState({'show_profile' : true})
        }
    }

    saveProfile = async()=>{
        var data = {
            "name" : this.state.name.trim()
        }
        if(this.state.name.trim().length == 0){
            toast.success("Please enter valid name.");
        }
        else{
            var response = await Api.PostRequest(data, 'profile')
            if(response.status == 200){
                await localStorage.setItem('name', data.name);
                toast.success("Profile updated successfully.");
                this.setState({'show_profile' : false})
            }
            else{
                toast.error(response.message);
            }
        }
        
    }


    openReview = async(product_id)=>{

        this.setState({
            'id' : "",
            'rating' : 0,
            'review' : "",
            "product_id" : "",
            "review_rating_images" : [],
            "new_uploaded_files" : [],
            "preview_files" : [],
            "old_deleted_images" : []
        })

        var params = {
            'product_id' : product_id
        }
        var response = await Api.GetRequest(params, 'review-and-rating');
        if(response.status == 200){
            if(Object.keys(response.review).length > 0){
                this.setState({
                    'id' : response.review.id,
                    'rating' : response.review.rating,
                    'review' : response.review.review,
                    'product_id' : product_id,
                    "review_rating_images" : response.review.review_rating_images,
                   
                });
            }
            else{
                this.setState({'product_id' : product_id})
            }
            this.setState({"show_review" : true})
        }
        
    } 

    handleClose = async() =>{
        this.setState({'show_profile' : false});
    }

    render() {
        return(
            <>  

                <Modal show={this.state.show_profile} onHide={()=>{this.handleClose()}}>
                    <Modal.Header>
                        <Modal.Title>Complete Profile</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="justify-content-center d-flex align-items-center flex-column">
                        <>
                            <div className="input-group">
                                <input onChange={(event) => {this.setState({"name" : event.target.value})}} className="form-control" type="name" name="name" placeholder="Your name."/>
                                <span className="input-group-addon"><i className="icon-user"></i></span>
                            </div>
                            <div className="mt-2">
                                <button onClick={()=>{this.saveProfile()}} class="btn btn-md btn-primary" href="javascript:;"><span>SAVE</span></button>
                            </div>
                        </>
                    </Modal.Body>
                </Modal>

                <Modal size='lg' show={this.state.show_review} onHide={()=>{this.setState({'show_review' : false})}}>
                    <form onSubmit={(event)=>{this.submitReview(event)}}>
                        <Modal.Header closeButton>
                            <Modal.Title>Rating & Reviews</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            
                                <h6>Rate This Product</h6>
                                <div className='justify-content-center d-flex align-items-center flex-row'>
                                    <ReactStars
                                        count={5}
                                        isHalf={false}
                                        value={this.state.rating}
                                        onChange={(rating)=>{this.setState({'rating' : rating})}}
                                        size={40}
                                        activeColor="#ffd700"
                                    />
                                </div>
                                
                                <h6>Review This Product</h6>
                                <div className='form-group'>
                                    <textarea required className="form-control" value={this.state.review} onChange={(event)=>{this.setState({'review' : event.target.value})}}>{this.state.review}</textarea>
                                </div>



                                <h6>Upload Product Image(s)</h6>

                                <div className='row'>

                                    {this.state.review_rating_images.map((image, index)=>{
                                        return(
                                            <div className='col-3 p-2'>
                                                <div className='card'>
                                                    <i onClick={()=>{this.deleteFromOldImage(index, image.id)}} className='fa fa-times' style={{"color": "red", "background": "#000000", "padding": "6px 10px", "width": "36px", "fontSize": "23px", "borderRadius": "100%", "position": "absolute", "right": "-6px", "top": "-6px"}} />
                                                    <img src={process.env.REACT_APP_URL + image.image} style={{'height' : '170px'}}/>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    
                                    {this.state.preview_files.map((image, index)=>{
                                        return(
                                            <div className='col-3 p-2'>
                                                <div className='card'>
                                                    <i onClick={()=>{this.deleteFromNewImage(index)}} className='fa fa-times' style={{"color": "red", "background": "#000000", "padding": "6px 10px", "width": "36px", "fontSize": "23px", "borderRadius": "100%", "position": "absolute", "right": "-6px", "top": "-6px"}} />
                                                    <img src={image} style={{'height' : '170px'}}/>
                                                </div>
                                            </div>
                                        )
                                    })}


                                    <div className='col-3 p-2'>
                                        <div className='card' style={{padding: 0}}>
                                            <input type='file' multiple accept='image/*' onChange={(event)=>{this.uploadImages(event)}} style={{'opacity' : 0, 'position' : 'absolute', top:0, bottom :0 , left : 0, right : 0}}/>
                                            <img src="assets/images/placeholder.jpg" style={{'height' : '170px'}}/>
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                                                
                        </Modal.Body>
                        <Modal.Footer>
                                <button type="button" onClick={()=>{this.setState({'show_review' : false})}} className='btn'>Cancel</button>
                                <button type="submit" className='btn btn-primary'>Submit</button>
                        </Modal.Footer>
                    </form>   
                </Modal>


                <div className="page-title">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <ul className="breadcrumbs">
                                    <li><Link to="home">Home</Link> </li>
                                    <li className="separator"></li>
                                    <li>Invoice</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="container padding-bottom-3x mb-1 print_invoice">
                    <div className="card invoice-card-container p-5">
                        
                        <div className="row">
                            <div className="col text-center">
                                <img className="img-fluid mb-5 mh-70" src="assets/images/logo.png" alt="LOGO" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4" style={{'textAlign' : 'left'}}>
                                <h5><b>Order Details :</b></h5>
                                {/* <span className="text-muted">Transaction Id :</span><br></br> */}
                                <span className="text-muted">Order Id :</span>&emsp; {this.state.order.booking_id}<br></br>
                                <span className="text-muted">Order Date :</span>&emsp; {moment(this.state.order.created_at).format('lll')}<br></br>

                                <br></br>
                                <br></br>
                            </div>

                            <div className="col-md-6" style={{'textAlign' : 'left'}}>
                                <h5><b>Billing Address :</b></h5>
                                <span className="text-muted">Name: </span>&emsp; {this.state.order.name}<br></br>
                                <span className="text-muted">Phone: </span>&emsp; {this.state.order.mobile}<br></br>
                                <span className="text-muted">Address: </span>&emsp; {this.state.order.address},{this.state.order.city}, {this.state.order.zipcode}<br></br>
                            </div>

                            <div className="col-md-2" style={{'textAlign' : 'left'}}>
                                <h6><b>Payment Status :</b></h6>
                                {this.state.order.payment_status == 0 &&
                                    <span className="text-danger">Pending</span>
                                }
                                {this.state.order.payment_status == 1 &&
                                    <span className="text-success">Complete</span>
                                }

                                <h6 className='mt-3'><b>Payment Mode :</b></h6>
                                {this.state.order.payment_mode == 0 &&
                                    <span className="text-danger">Online</span>
                                }
                                {this.state.order.payment_mode == 1 &&
                                    <span className="text-success">COD</span>
                                }
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-12">
                                <div className="gd-responsive-table invoice-table-web">
                                    <table className="table table-responsive table-bordered my-4">
                                        <thead>
                                            <tr>
                                                <th className="px-0 bg-transparent border-top-0" style={{"max-width": "35%", "width": "100%"}}>
                                                    <b>Product</b>
                                                </th>
                                                <th className="px-0 bg-transparent border-top-0">
                                                    <b>Color</b>
                                                </th>
                                                <th className="px-0 bg-transparent border-top-0">
                                                    <b>Size</b>
                                                </th>
                                                <th className="px-0 bg-transparent border-top-0">
                                                    <b>Quantity</b>
                                                </th>
                                                <th className="px-0 bg-transparent border-top-0">
                                                    <b>Price</b>
                                                </th>
                                                <th className="px-0 bg-transparent border-top-0">
                                                    <b>Discount</b>
                                                </th>
                                                <th className="px-0 bg-transparent border-top-0 text-right">
                                                    <b>Sub Total</b>
                                                </th>
                                                <th className="px-0 bg-transparent border-top-0 text-right">
                                                    <b></b>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                        {this.state.order.booking_items.map((product)=>{
                                            return(
                                                <tr>
                                                    <td style={{'verticalAlign' : 'middle','alignItems' :'center', 'display' : 'flex', 'justifyContent' : 'flex-start'}} className="">
                                                        <Link  className='d-flex flex-row align-items-center' style={{'color' : "rgb(255, 106, 0)"}} to={`/product/product-details/${product.product_id}`}>
                                                            <img className="lazy mx-2" src={process.env.REACT_APP_MEDIA_URL + product.image} alt="Product" style={{'width':"70px", 'height' : '70px'}}/>
                                                            <div>{product.name}</div>
                                                        </Link>
                                                        
                                                    </td>
                                                    <td style={{'verticalAlign' : 'middle'}} className="px-0">
                                                        {product.color &&
                                                            <span>{product.color}</span>
                                                        }
                                                        {!product.color &&
                                                            <span>-</span>
                                                        }
                                                    </td>
                                                    <td style={{'verticalAlign' : 'middle'}} className="px-0">
                                                        {product.size &&
                                                            <span>{product.size}</span>
                                                        }
                                                        {!product.size &&
                                                            <span>-</span>
                                                        }
                                                    </td>
                                                    <td style={{'verticalAlign' : 'middle'}} className="px-0">
                                                        {product.quantity}
                                                    </td>
                                                    <td style={{'verticalAlign' : 'middle'}} className="px-0">₹  {product.price.toFixed(0)}</td>
                                                    <td style={{'verticalAlign' : 'middle'}} className="px-0">{product.discount} % OFF</td>
                                                    <td style={{'verticalAlign' : 'middle'}} className="px-0 text-right">₹  {((product.price * product.quantity) - product.net_discount).toFixed(0)}</td>
                                                    <td style={{'verticalAlign' : 'middle'}} className="px-0 text-right"> <b onClick={()=>{this.checkProfile(product.product_id)}} style={{'color' : '#FF6A00'}}>Rate & Review</b> </td>
                                                </tr>
                                            )
                                        })}
                                        

                                        </tbody>
                                    </table>
                                </div>

                                <div className="invoice-table-mobile my-3">
                                    {this.state.order.booking_items.map((product)=>{
                                        return(
                                            <div className='card m-2 bordered' style={{'border' : '1px solid #e5e5e5', width:'100%'}}>
                                                <div className='card-body'>
                                                    <div className='w-100 justify-content-start d-flex align-items-flex-start flex-row'>
                                                        <Link to={`/product/product-details/${product.product_id}`}  style={{'verticalAlign' : 'middle', color:'#FF6A00 !important'}} href="javascript::void(0)">
                                                            <img style={{'width':'100px', 'height':'100px', 'objectFit':'contain'}} src={process.env.REACT_APP_MEDIA_URL + product.image } alt="Product"/>    
                                                        </Link>
                                                        <div style={{'marginLeft' : '10px'}} className="w-100 justify-content-center d-flex align-items-baseline flex-column">
                                                            <Link to={`/product/product-details/${product.product_id}`} class="product-info" style={{'verticalAlign' : 'middle', color:'#FF6A00 !important'}}>
                                                                <p style={{'color' : '#FF6A00', textAlign : 'left'}} className="product-title" >{product.name}</p>
                                                            </Link> 

                                                            <div style={{'width' : '100%'}}  className='my-1 justify-content-start d-flex align-items-center flex-row flex-wrap'>
                                                                {product.color &&
                                                                    <div className='px-1 py-2 mx-1 my-1' style={{'background' : '#f3f5f6'}}>
                                                                        <label style={{'fontSize' : '12px', 'fontWeight' : 'bold', 'textAlign' : 'left'}}>Color : </label>
                                                                        <span>&emsp;{product.color}</span> 
                                                                    </div>
                                                                }
                                                            
                                                                {product.size &&
                                                                    <div className='px-1 py-2 mx-1 my-1' style={{'background' : '#f3f5f6'}}>
                                                                        <label style={{'fontSize' : '12px', 'fontWeight' : 'bold', 'textAlign' : 'left'}}>Size : </label>
                                                                        <span>&emsp;{product.size}</span> 
                                                                    </div>
                                                                }
                                                                
                                                                <div className='px-1 py-2 mx-1 my-1' style={{'background' : '#f3f5f6'}}>
                                                                    <label style={{'fontSize' : '12px', 'fontWeight' : 'bold', 'textAlign' : 'left'}}>Quantity : </label>
                                                                    <span>&emsp;{product.quantity}</span> 
                                                                </div>

                                                              
                                                            </div>
                                                            
                                                            <div style={{'width' : '100%'}} className='my-1 w-100 justify-content-start d-flex align-items-center flex-row'>
                                                                {product.discount > 0 &&
                                                                    <div style={{'margin' : '10px 0px', fontSize:'12px', textDecorationLine: 'line-through'}}>₹ {product.price}</div>
                                                                }
                                                                {product.discount == 0 &&
                                                                    <div style={{'margin' : '10px 0px', fontSize:'12px'}}>₹ {product.price}</div>
                                                                }
                                                                {product.discount > 0 &&
                                                                    <div style={{'padding': '3px 10px', fontSize:'12px', 'background': 'limegreen', fontWeight:'bold', 'margin': '10px', 'borderRadius': '10px'}}> ₹ {product.discount} OFF </div>  
                                                                }
                                                                {product.discount > 0 &&
                                                                    <div style={{'margin' : '10px 0px', fontSize:'12px'}}>₹ {(product.price - product.discount).toFixed(0)}</div> 
                                                                }
                                                                
                                                                <div style={{'color' : '#FF6A00', fontSize:'12px', marginLeft:'13%'}} onClick={()=>{this.checkProfile(product.product_id)}} class="rate-review">Rate & Review</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                                
                        <Accordion>
                            <Accordion.Item className="my-3" eventKey="0">
                                <Accordion.Header>Order Tracking</Accordion.Header>
                                    <Accordion.Body>
                                    <VerticalTimeline lineColor={'#000000'}>
                                        {this.state.order_tracks.map((track)=>{
                                            return(
                                                <VerticalTimelineElement
                                                    className="vertical-timeline-element--work"
                                                    contentStyle={{ background: 'rgb(255, 106, 0)', paddingTop:10, paddingBottom:10, color: '#fff' }}
                                                    contentArrowStyle={{ borderRight: '7px solid  rgb(255, 106, 0)', top:'16px' }}
                                                    date="2011 - present"
                                                    iconStyle={{ background: 'rgb(255, 106, 0)', width:'25px', top:'15px', height:'25px', color: '#fff' }}
                                                >
                                                    <b className="vertical-timeline-element-title" style={{'color' : '#FFFFFF'}}>{track.title}</b><br></br>
                                                    <b className="vertical-timeline-element-subtitle" style={{'color' : '#FFFFFF'}}>{moment(track.description).format('lll')}</b>
                                                </VerticalTimelineElement>  
                                            )
                                        })}                                
                                    </VerticalTimeline>
                                    </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        

                        <div  className="row">
                            <div className="col-12">
                                <div className="gd-responsive-table justify-content-end d-flex align-items-end">
                                    <table className='table table-bordered w-50 invoice-bill-table'>
                                        <tbody>
                                            <tr>
                                                <th style={{'textAlign' : 'left'}}>MRP Total</th>
                                                <td style={{'textAlign' : 'right'}}>₹ {this.state.order.total_price.toFixed(0)}</td>
                                            </tr>
                                            <tr>
                                                <th style={{'textAlign' : 'left'}}>Discount</th>
                                                <td style={{'textAlign' : 'right'}}>-₹ {this.state.order.total_discount.toFixed(0)}</td>
                                            </tr>
                                            {this.state.coupon_discount > 0 &&
                                                <tr>
                                                    <th style={{'textAlign' : 'left'}}>Coupon Discount</th>
                                                    <td style={{'textAlign' : 'right'}}>-₹ {this.state.order.coupon_discount.toFixed(0)}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th style={{'textAlign' : 'left'}}>Shipping Charge</th>
                                                <td style={{'textAlign' : 'right'}}>
                                                    {this.state.order.shipping_charge > 0 &&
                                                        <>
                                                            ₹ {this.state.order.shipping_charge}
                                                        </>
                                                    }
                                                    {this.state.order.shipping_charge == 0 &&
                                                        <div style={{'justifyContent' : 'flex-end', 'display' : 'flex', 'flexDirection' : 'row', 'alignItems' : 'center'}}>
                                                            <span style={{'textDecorationLine' : 'line-through'}} className='mx-2'>₹ {this.state.order.shipping_charge}</span>
                                                            <span style={{color :'#2ec500'}} className='ml-2'>FREE</span>
                                                        </div>
                                                        
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{'textAlign' : 'left'}}>To Pay</th>
                                                <td style={{'textAlign' : 'right'}}>
                                                    {this.state.order.total_price - this.state.order.total_discount  - this.state.order.coupon_discount + this.state.order.shipping_charge}
                                                </td>
                                            </tr>
                                        </tbody>
                                        
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}



export default withRouter(Invoice);


