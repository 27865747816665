import React from 'react';
import Api from '../../Services/api';
import Login from '../auth/Login';
import withRouter from '../../Services/withRouter';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import NewAddress from '../addresses/NewAddress.js';
import EditAddress from '../addresses/EditAddress.js';
import Pagination from "react-js-pagination";
import moment from 'moment';

class Profile extends React.Component{


    componentDidMount = async()=>{
       await this.verifyUser();
       await this.getAddresses();
       await this.getOrders();
    }



    constructor(props){
        super(props);
        this.state = {
            'section' : 1,
            'name' : "",
            'mobile' : "",
            'email' : "",
            'orders' : [],
            'addresses' : [],
            'show_new_address' : false,
            'show_edit_address' : false,
            'address' : {},
            'active_page' : 1
        }
    }


   

    verifyUser = async()=>{
        var response = await Api.GetRequest({}, 'verify-user');
        if(response.status == 200){
            this.setState({
                'name' : response.name,
                'mobile' : response.mobile
            });
        }   
        else{
            
        } 
    }



    getOrders = async()=>{
        var response = await Api.GetRequest({}, 'orders');
        if(response.status == 200){
            this.setState({'orders' : response.orders});
        }   
        else{
            
        } 
    }


    getAddresses = async()=>{
        var response = await Api.GetRequest({}, 'addresses');
        if(response.status == 200){
            this.setState({'addresses' : response.addresses});
        }   
        else{
            
        } 
    }



    setSection = async(section)=>{
        this.setState({'section' : section});
    }




    validateProfile = async()=>{
        this.updateProfile();
    }



    updateProfile = async()=>{
        var data = {
            "name" : this.state.name.trim(),
            "email" : this.state.email.trim()
        }

        var response = await Api.PostRequest(data, 'profile')
        if(response.status == 200){
            await localStorage.setItem('name', data.name);
            toast.success("Profile updated successfully.");
        }
        else{

        }
    }



    deleteAddress = async(address_id)=>{
        var data = {
            'id' : address_id
        }
        var response = await Api.PostRequest(data, 'delete-address');
        if(response.status == 200){
            this.getAddresses();
            toast.success("Address deleted successfully.");
        }
        else{
            toast.error("Something went wrong. Please try again later.")
        }
    }



    addNewAddress = async()=>{
        this.setState({'show_new_address' : true});
    }


    handleNewAddress = async()=>{
        await this.getAddresses();
        this.setState({'show_new_address' :  false});
    }


    editAddress = async(address)=>{
        this.setState({
            'address' : address,
            'show_edit_address' : true
        });
    }


    handleEditAddress = async()=>{
        await this.getAddresses();
        this.setState({'show_edit_address' :  false});
    }


    logout = async()=>{
        localStorage.clear();
        this.props.navigate("/home");
        this.props.checkUserLogin();
    }
    

    render() {
        return(
            <>                
                <div class="page-title">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <ul class="breadcrumbs">
                                    <li><Link to="/">Home</Link> </li>
                                    <li class="separator"></li>
                                    <li>Profile</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container padding-bottom-3x mb-1">
                    <div class="row">
                        <div class="col-lg-4">
                            <aside class="user-info-wrapper">
                            <div class="user-info">
                                <div class="user-avatar">
                                    <img id="avater_photo_view" src="http://pavilioninfra.com/e-commerce1/assets/images/placeholder.png" alt="User"/>
                                </div>

                                <div class="user-data">
                                <h4 class="h5">{this.state.name}</h4><span>{this.state.mobile}</span>
                                </div>
                            </div>
                            <nav class="list-group">
                                <a onClick={()=>{this.setSection(1)}} className={`list-group-item ${this.state.section == 1 ? 'active' : ''}`}  style={{'textAlign' : 'left'}} href="javascript:;"><i class="icon-user"></i>Profile</a>
                                <a onClick={()=>{this.setSection(2)}} className={`list-group-item ${this.state.section == 2 ? 'active' : ''}`}  style={{'textAlign' : 'left'}} href="javascript:;"><i class="icon-home"></i>Addresses</a>
                                <a onClick={()=>{this.setSection(3)}} className={`list-group-item ${this.state.section == 3 ? 'active' : ''}`}  style={{'textAlign' : 'left'}} href="javascript:;"><i class="icon-shopping-bag"></i>Orders</a>
                                <a onClick={()=>{this.logout()}} className={`list-group-item ${this.state.section == 4 ? 'active' : ''}`}  style={{'textAlign' : 'left'}} href="javascript:;"><i class="icon-log-out"></i>Log out</a>
                            </nav>
                            </aside>
                        </div>
                        <div class="col-lg-8">

                            {this.state.section == 1 &&
                                <div class="card">
                                    <div class="card-body">
                                        <div class="padding-top-2x mt-2 hidden-lg-up"></div>
                                        <form class="row">                         
                                            
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="account-fn" className='justify-content-start d-flex align-items-start flex-row'>First Name</label>
                                                    <input class="form-control" onChange={(event)=> {this.setState({'name': event.target.value})}} name="name" type="text" id="account-fn" value={this.state.name}/>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <button onClick={()=>{this.validateProfile()}} class="btn btn-primary margin-right-none" type="button"><span>Update Profile</span></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            }

                            {this.state.section == 2 &&
                                <>
                                    <NewAddress show_new_address={this.state.show_new_address} handleNewAddress={this.handleNewAddress}></NewAddress>
                                    <EditAddress show_edit_address={this.state.show_edit_address} address={this.state.address} handleEditAddress={this.handleEditAddress}></EditAddress>
                                    {this.state.addresses.length > 0 &&
                                        <>
                                            <div style={{'justifyContent' : 'flex-end', display : 'flex', 'alignItems' : 'flex-end'}}>
                                                <button  id="continue__button" class="btn btn-primary btn-md" type="button" onClick={()=>{this.addNewAddress()}}><span class="hidden-xs-down">New Address</span></button>
                                            </div>
                                            {this.state.addresses.map((address)=>{
                                                return(
                                                    <div className='card' style={{'border' : "1px solid #EDEDED" }}>
                                                        <div className='card-body'>
                                                            <p style={{textAlign: 'left', fontWeight : 'bold', marginBottom : 0}}>{address.name}</p>
                                                            <p style={{textAlign: 'left', marginBottom : 0}}>{address.phone}</p>
                                                            
                                                            <p style={{textAlign: 'left', marginBottom : 0}}>{address.address}</p>

                                                            <p style={{textAlign: 'left', marginBottom : 0}}>{address.city}, {address.state}, {address.zipcode}</p>

                                                            <div style={{'justifyContent' : 'flex-end', 'display' : 'flex', 'flexDirection':'row', 'alignItems' : 'flex-end'}}>
                                                                <span style={{'textAlign' : 'right', 'color' : 'red', 'cursor' : "pointer"}} className='mx-4' onClick={()=>{this.editAddress(address)}}>Edit</span>
                                                                <span style={{'textAlign' : 'right', 'color' : 'red', 'cursor' : "pointer"}} onClick={()=>{this.deleteAddress(address.id)}}>Delete</span>
                                                            </div>
                            
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </>
                                    }

                                    {this.state.addresses.length == 0 &&
                                        <>
                                            <div className='card' style={{'border' : "1px solid #EDEDED" }}>
                                                <div style={{'justifyContent' : 'flex-end', display : 'flex', 'alignItems' : 'flex-end'}}>
                                                    <button  id="continue__button" class="btn btn-primary btn-md" type="button" onClick={()=>{this.addNewAddress()}}><span class="hidden-xs-down">New Address</span></button>
                                                </div>
                                                <div className='card-body'>                                                
                                                    <img src="assets/images/no_address.png" alt="LOGO" style={{"width" : "50%"}}/>
                                                </div>
                                            </div>
                                            
                                        </>
                                    }
                                    
                                </>                                
                            }
                           
                            {this.state.section == 3 &&
                                <div class="card">
                                    <div class="card-body">
                                        {this.state.orders.length > 0 &&
                                            <div class="u-table-res">
                                                <table class="table table-bordered mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Order #</th>
                                                            <th>Total Amount</th>
                                                            <th>Date Purchased</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    
                                                    <tbody>
                                                            {this.state.orders.slice(((this.state.active_page - 1 )* 10), (this.state.active_page * 10)).map((order)=>{
                                                                return(
                                                                    <tr>
                                                                        <td><a class="navi-link" href="#" data-toggle="modal" data-target="#orderDetails">{order.booking_id}</a></td>
                                                                        <td>₹ {order.price - order.discount + order.shipping_charge - order.coupon_discount}</td>
                                                                        <td>{moment(order.created_at).format('lll')}</td>
                                                                        <td><Link to={`/order/invoice/${order.id}`} class="btn btn-info btn-sm">Invoice</Link></td>
                                                                    </tr>
                                                                )
                                                            })}
                                                            
                                                    </tbody>
                                                </table>
                                                <Pagination
                                                    activePage={this.state.active_page}
                                                    itemsCountPerPage={10}
                                                    totalItemsCount={this.state.orders.length}
                                                    // pageRangeDisplayed={Math.round(this.state.active_page / 5)}
                                                    onChange={(page)=>{
                                                        this.setState({'active_page' : page})
                                                        console.log(this.state.active_page)
                                                    }}
                                                />
                                            </div>
                                        }
                                        {this.state.orders.length == 0 &&
                                            <img src="assets/images/no_orders.png" style={{'width' : '56%'}}/>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}



export default withRouter(Profile);


