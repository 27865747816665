import React from "react";


class Register extends React.Component{

    constructor(props){
        super(props);

        this.state={
            
        }
    }



    render(){
        return(
            <>
                <div class="card register-area">
                    <div class="card-body ">
                        <h4 class="margin-bottom-1x text-center">Register</h4>
                        <form class="row" action="https://pavilioninfra.com/e-commerce1/user/register-submit" method="POST">
                            <div class="col-sm-6">
                                <div class="form-group d-flex align-items-start flex-column">
                                    <label for="reg-fn">First Name</label>
                                    <input class="form-control" type="text" name="first_name" placeholder="First Name" id="reg-fn" value="" />
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group d-flex align-items-start flex-column">
                                    <label for="reg-ln">Last Name</label>
                                    <input class="form-control" type="text" name="last_name" placeholder="Last Name" id="reg-ln" value="" />
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group d-flex align-items-start flex-column">
                                    <label for="reg-email">E-mail Address</label>
                                    <input class="form-control" type="email" name="email" placeholder="E-mail Address" id="reg-email" value=""/>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group d-flex align-items-start flex-column">
                                    <label for="reg-phone">Phone Number</label>
                                    <input class="form-control" name="phone" type="text" placeholder="Phone Number" id="reg-phone" value=""/>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group d-flex align-items-start flex-column">
                                    <label for="reg-pass">Password</label>
                                    <input class="form-control" type="password" name="password" placeholder="Password" id="reg-pass"/>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group d-flex align-items-start flex-column">
                                    <label for="reg-pass-confirm">Confirm Password</label>
                                    <input class="form-control" type="password" name="password_confirmation" placeholder="Confirm Password" id="reg-pass-confirm" />
                                </div>
                            </div>

                            <div class="col-lg-12 mb-4">
                                <script src="../../../www.google.com/recaptcha/apid41d.js?" async defer></script>
                                <div data-sitekey="6LcnPoEaAAAAAF6QhKPZ8V4744yiEnr41li3SYDn" class="g-recaptcha"></div>
                            </div>

                            <div class="col-12 text-center">
                                <button class="btn btn-primary margin-bottom-none" type="submit"><span>Register</span></button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }

}


export default Register;