import React from "react";


class Login extends React.Component{

    constructor(props){
        super(props);

        this.state={
            
        }
    }



    render(){
        return(
            <>
                <form class="card" method="post" action="https://pavilioninfra.com/e-commerce1/user/login-submit">
                    <div class="card-body ">
                        <h4 class="margin-bottom-1x text-center">Login</h4>

                        <div class="form-group input-group">
                            <input class="form-control" type="email" name="login_email" placeholder="Email" value="" />
                            <span class="input-group-addon"><i class="icon-mail"></i></span>
                        </div>

                        <div class="form-group input-group">
                            <input class="form-control" type="password" name="login_password" placeholder="Password"/>
                            <span class="input-group-addon"><i class="icon-lock"></i></span>
                        </div>

                        <div class="d-flex flex-wrap justify-content-between padding-bottom-1x">
                            <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" type="checkbox" id="remember_me"/>
                                <label class="custom-control-label" for="remember_me">Remember me</label>
                            </div>
                            
                            <a class="navi-link" href="forgot.html">Forgot password?</a>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-primary margin-bottom-none" type="submit"><span>Login</span></button>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 text-center mt-3">
                                <a class="facebook-btn mr-2" href="https://www.facebook.com/v3.3/dialog/oauth?client_id=643929170080071&amp;redirect_uri=https%3A%2F%2Fpavilioninfra.com%2Fe-commerce1%2Fauth%2Ffacebook%2Fcallback&amp;scope=email&amp;response_type=code&amp;state=dYsLPZfu5DHoHZG6G4LR2qQ34Yicf6wm7BybaAbg">Facebook login</a>
                                <a class="google-btn" href="https://accounts.google.com/o/oauth2/auth?client_id=915191002660-6hjno4cgnbcm5p1kb3t692trh7pc6ngh.apps.googleusercontent.com&amp;redirect_uri=https%3A%2F%2Fpavilioninfra.com%2Fe-commerce1%2Fauth%2Fgoogle%2Fcallback&amp;scope=openid+profile+email&amp;response_type=code&amp;state=xndk7qL5ZqGS14GigaUxcVWbeSN6koCxOVa6cVo3">Google login </a>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        )
    }

}


export default Login;