import React from 'react';

class CompareProducts extends React.Component{

    constructor(props){
        super(props);
        this.state={

        }
    }


    render(){
        return(
            <>
                <div class="page-title">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <ul class="breadcrumbs">
                                    <li><a href="https://pavilioninfra.com/e-commerce1">Home</a> </li>
                                    <li class="separator"></li>
                                    <li>Compare Products</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container padding-bottom-3x mb-1">
                    <div class="card">
                        <div class="card-body">
                            <div class="comparison-table">
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr class="bg-secondary">
                                            <th class="text-uppercase">Summary</th>
                                            <td><span class="text-medium">Men Shirt Custom Shirts Hot Sale Men Women Polyester Cotton Long Sleeve Casual pro</span></td>
                                            <td><span class="text-medium">UMIDIGI A9 Pro Android Mobile Phone 4g 48MP Quad Camera 6.3" FHD+ Full Screen 6GB RAM</span></td>

                                        </tr>
                                        <tr>
                                            <td>
                                                <h6>Men Shirt Custom Shirts Hot Sale Men Women Polyester Cotton Long Sleeve Casual pro</h6>
                                                <p>
                                                    <b>Brand</b> : , <b>Price</b> :  $1,362.81
                                                </p>

                                                <hr/>
                                                <h6 class="mt-2">UMIDIGI A9 Pro Android Mobile Phone 4g 48MP Quad Camera 6.3" FHD+ Full Screen 6GB RAM</h6>
                                                <p>
                                                    <b>Brand</b> :  , <b>Price</b> :  $1,573.03</p>
                                            </td>
                                            
                                            <td>
                                                <div class="comparison-item"><span class="remove-item compare_remove" data-href="https://pavilioninfra.com/e-commerce1/compare/remove/563"><i class="icon-x"></i></span><a class="comparison-item-thumb" href="https://pavilioninfra.com/e-commerce1/product/Men-Shirt-Custom-Shirts-Hot-Sale-Men-Women-Polyester-Cotton-Long-Sleeve-Casual-pro"><img src="https://pavilioninfra.com/e-commerce1/assets/images/1634134720QX6Hedf90cf6656546e7a8548d4980edc5bda.jpg" alt="Image"/></a><a class="comparison-item-title" href="https://pavilioninfra.com/e-commerce1/product/Men-Shirt-Custom-Shirts-Hot-Sale-Men-Women-Polyester-Cotton-Long-Sleeve-Casual-pro">Men Shirt Custom Shirts Hot Sale Men Women Polyester Cotton Long Sleeve Casual pro</a><a class="btn btn-outline-primary btn-sm add_to_single_cart" href="javascript:;" data-target="563">Add to Cart</a></div>
                                            </td>
                                            
                                            <td>
                                                <div class="comparison-item"><span class="remove-item compare_remove" data-href="https://pavilioninfra.com/e-commerce1/compare/remove/540"><i class="icon-x"></i></span><a class="comparison-item-thumb" href="https://pavilioninfra.com/e-commerce1/product/UMIDIGI-A--Pro-Android-Mobile-Phone--g---MP-Quad-Camera------FHD--Full-Screen--GB-RAM"><img src="https://pavilioninfra.com/e-commerce1/assets/images/1634134938VjgHcb62dec2d6a241fc90ce2bb04059684em.jpg" alt="Image"/></a><a class="comparison-item-title" href="https://pavilioninfra.com/e-commerce1/product/UMIDIGI-A--Pro-Android-Mobile-Phone--g---MP-Quad-Camera------FHD--Full-Screen--GB-RAM">UMIDIGI A9 Pro Android Mobile Phone 4g 48MP Quad Camera 6.3" FHD+ Full Screen 6GB RAM</a><a class="btn btn-outline-primary btn-sm add_to_single_cart" href="javascript:;" data-target="540">Add to Cart</a></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Product Type:</th>
                                            <td>Velvet elegant sleeveless evening dress</td>
                                            <td>Velvet elegant sleeveless evening dress</td>
                                        </tr>
                                        <tr>
                                            <th>Material:</th>
                                            <td>Polyester / Spandex</td>
                                            <td>Polyester / Spandex</td>
                                        </tr>
                                        <tr>
                                            <th>Lining Material:</th>
                                            <td>Polyester</td>
                                            <td>Polyester</td>
                                        </tr>
                                        <tr>
                                            <th>Fabric Type:</th>
                                            <td>Fleece</td>
                                            <td>Fleece</td>
                                        </tr>
                                        <tr>
                                            <th>Technics:</th>
                                            <td>Plain dyed</td>
                                            <td>Plain dyed</td>
                                        </tr>
                                                                
                                        <tr>
                                            <th>Decoration:</th>
                                            <td>Sequins</td>
                                            <td>Sequins</td>
                                        </tr>
                                        <tr>
                                            <th>Size:</th>
                                            <td>S/M/L</td>
                                            <td>S/M/L</td>
                                        </tr>
                                                                
                                        <tr>
                                            <th></th>
                                            <td>
                                                <a class="btn btn-outline-primary btn-sm btn-block add_to_single_cart" href="javascript:;" data-target="563">Add to Cart</a>
                                            </td>

                                            <td>
                                                <a class="btn btn-outline-primary btn-sm btn-block add_to_single_cart" href="javascript:;" data-target="540">Add to Cart</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}


export default CompareProducts;