/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Api from '../Services/api';

class ContactUs extends React.Component {

    constructor() {
        super();
        this.state = {
            "name" : "",
            "email" : "",
            "message" : "",
            "is_disabled" : false
        };
    }


    validateMail = async()=>{
        if(this.state.name.trim().length < 3){
            toast.error("Please enter valid name.");
        }
        else if(this.state.message.trim().length == 0){
            toast.error("Please enter message.");
        }
        else{
            this.validateEmail();
        }
    }



    validateEmail = async()=>{
        var emailregx = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w\w+)+$/;
        var res = await emailregx.test(this.state.email);
        if(res){
           this.sendMail();
        }
        else{
            toast.error("Please enter valid email id.");
        }
    
    }



    sendMail = async()=>{
        this.setState({'is_disabled' : true});
        var data = {
            'name' : this.state.name.trim(),
            'email' : this.state.email.trim(),
            'message' : this.state.message.trim()
        };
        var response = await Api.PostRequest(data, 'enquiry');
        if(response.status == 200){
            toast.success(response.message);
            this.setState({
                'message' : "",
                'name'  : "",
                'email' : ""
            });
            this.props.navigate("home");
        }
        else{
            toast.error(response.message);
        }

        this.setState({'is_disabled' : false});
    }


    render() {
        return (
            <>                
                <div className="container padding-bottom-3x mb-1 contact-page">


                    <div className="row">
                        <div className="col-lg-4 col-md-5 col-sm-5 order-lg-1 order-md-2 order-sm-2">

                            <section className="widget widget-featured-posts card rounded p-4 ">
                                <h3 className="widget-title padding-bottom-1x" style={{'textAlign' : 'left'}}>Working Days</h3>
                                <ul className="list-unstyled text-sm justify-content-start d-flex align-items-start flex-column">
                                    <li><span className="text-muted" style={{'textAlign' : 'left', width : '100%'}}>Monday-Friday:</span>9:27 PM - 9:27 PM</li>
                                    <li><span className="text-muted" style={{'textAlign' : 'left', width : '100%'}}>Saturday:</span>9:27 PM - 9:27 PM</li>
                                </ul>
                            </section>
                           
                            <section className="widget widget-featured-posts card rounded p-4">
                                <h3 className="widget-title padding-bottom-1x" style={{'textAlign' : 'left'}}>Store address</h3>
                                <p>Our address information</p>
                                <ul className="list-icon margin-bottom-1x justify-content-start d-flex align-items-start flex-column">
                                    <li> <i className="icon-map-pin text-muted"></i>514 S. Magnolia St. Orlando, FL 32806, USA</li>
                                    <li> <i className="icon-phone text-muted"></i>{process.env.REACT_APP_CALL}</li>
                                </ul>
                                <div>
                                    <Link className="social-button shape-circle sb-facebook" to={process.env.REACT_APP_FACEBOOK_LINK} data-toggle="tooltip"
                                    data-placement="top"><i className="fab fa-facebook-f"></i></Link>
                                    <a className="social-button shape-circle sb-facebook" to={process.env.REACT_APP_YOUTUBE_LINK}  data-toggle="tooltip"
                                    data-placement="top"><i className="fab fa-youtube"></i></a>
                                    <a className="social-button shape-circle sb-facebook" to={process.env.REACT_APP_INSTAGRAM_LINK} data-toggle="tooltip"
                                    data-placement="top"><i className="fab fa-instagram"></i></a>
                                </div>
                            </section>
                        </div>

                        <div className="col-lg-8 col-md-7 col-sm-7 order-lg-2 order-md-1 order-sm-1">
                            <div className="contact-form-box card">

                            <h2 className="h4">Tell Us Your Message :</h2>
                            <form  className="row mt-2">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label for="name" style={{'textAlign' : 'left', 'width' : '100%'}}>Name</label>
                                        <input className="form-control form-control-rounded" value={this.state.name} onChange={(e)=>{this.setState({'name' : e.target.value})}} name="name" type="text" id="name" placeholder="Name" required/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label for="email" style={{'textAlign' : 'left','width' : '100%'}}>E-mail</label>
                                        <input className="form-control form-control-rounded" type="email" name="email" value={this.state.email} onChange={(e)=>{this.setState({'email' : e.target.value})}} id="email" placeholder="email" required/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label for="message-text" style={{'width' : '100%', textAlign : 'left'}}>Message</label>
                                        <textarea className="form-control form-control-rounded" rows="9" value={this.state.message} onChange={(e)=>{this.setState({'message' : e.target.value})}} name="message" id="message-text"
                                        placeholder="Write your message here..."></textarea>
                                    </div>
                                </div>
                                
                                <div className="col-12 text-right">
                                    <button className="btn btn-primary" onClick={()=>{this.validateMail()}} type="button"><span>Send message</span></button>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>        
        )
    }
}

export default ContactUs