import React, { Component } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import withRouter from '../../Services/withRouter';
import Api from '../../Services/api';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import parse from 'html-react-parser';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import ImageViewer from 'react-simple-image-viewer';
import ProgressBar from 'react-bootstrap/ProgressBar';


import {EmailIcon, FacebookShareButton, TwitterShareButton, FacebookIcon, EmailShareButton, XIcon, WhatsappShareButton, PinterestIcon, PocketIcon, TelegramIcon, TwitterIcon, WhatsappIcon,
  } from "react-share";

class ProductDetails extends Component{

    share_url = 'https://18stores.com/product/product-details/';


    componentDidMount =()=>{
        let id = this.props.params.id
        if(id){
            this.getProductDetails(id);
        }
        else{

        }
    }


    componentDidUpdate = async(prevProps, prevState)=>{
        if(this.props.params.id != this.state.product.id){
            this.getProductDetails(this.props.params.id);
        }
    }



    constructor(props){
        super(props);
        this.state={
            'product' : {
                'id' : "",
                'product_images' : [],
                'description' : "",
                'stylist_notes' : "",
                'product_sizes' : [],
                'product_colors' : [],
                'rating' : 0,
                'total_ratings' : 0,
                'product_ratings' : [],
                'review_images' : [],
                'rating_by_group' : []
            },
            "similar_products" : [],
            'color' : "",
            'size' : "",
            'show_reviews' : false,
            "show_review_images" : false,
            "review_images" : []
        }
        
    }



    getProductDetails = async(product_id)=>{
        window.scroll(0, 0);
        var params = {
            'product_id' : product_id 
        }
        var response = await Api.GetRequest(params, 'product');
        if(response.status == 200){
            
            // await response.product.product_images.splice(0, 0, {'image' : response.product.image, 'is_video' : false});
            if(response.product.video){
                await response.product.product_images.splice(0, 0, {'image' : response.product.video,'is_video' : true})
            }

            if(response.product.rating_by_group.length > 0){
                var rating_group = [];
                for(var i=5; i>=1; i--){
                    var index = await response.product.rating_by_group.findIndex((element)=>{return element.rating == i})
                    if(index == -1){
                        var rating = {
                            'rating' : i,
                            'total_rating' : 0
                        }    
                    }  
                    else{
                        var rating = response.product.rating_by_group[index]
                    }
                    rating_group.push(rating)
                }
                response.product.rating_by_group = rating_group
            }
            

            await this.setState({
                'product' : response.product,
                'active_image' : response.product.image
            });
        }
        else{
            toast.error("Product details doesn't exists.")
        }

        this.getSimilarProducts(product_id);
    }



    getSimilarProducts = async(product_id) => {
        var params = {
            'product_id' : product_id
        }
        var response = await Api.GetRequest(params, 'similar-products');
        if(response.status == 200){
            this.setState({'similar_products' : response.products});
        }
        else{
            toast.error("Something went wrong. Please try again later.")
        }
    }




    // setPreviewImage = async(index)=>{
    //     this.setState({
    //         'is_video' : this.state.product.product_images[index].is_video,
    //         'active_image' : this.state.product.product_images[index].image
    //     });
        
    // }



    addToCart = async()=>{
        var data = {
            'product_id' : this.state.product.id,
            'color' : this.state.color,
            'size' : this.state.size
        }
        if(this.state.product.product_colors.length > 0 && data.color == ""){
            toast.error("Please select color.")
        }
        else if(this.state.product.product_sizes.length > 0 && data.size == ""){
            toast.error("Please select size.")
        }
        else{
            var response = await Api.PostRequest(data, 'add-to-cart');
            if(response.status == 200){
                toast.success("Product added in your cart successfully.");
                this.props.fetchCart();
            }
            else{
                toast.error("Something went wrong. Please try again later.");
            }
        }
    }


    setColor = async(color)=>{
        if(this.state.color != color){
            await this.setState({
                'color' : color,
                'size' : "",
            });
            await this.getSizes();
        }      
    }


    getSizes = async()=>{
        var params = {
            'product_id' : this.state.product.id,
            'color' : this.state.color
        };
        var response = await Api.GetRequest(params, 'sizes')
        if(response.status == 200){
            await this.setState({ product:{
                ...this.state.product,
                'product_sizes': response.sizes
            }});
        }

    }



    setSize = async(size)=>{
        this.setState({'size' : size});
    }



    showReviewImages = async(review_images)=>{
        var images = [];
        for(const image of review_images){
            images.push(process.env.REACT_APP_URL + image.image);
        }
        this.setState({'review_images' : images, 'show_review_images' : true})
    }

    


    render(){
        return(
            <>
                <Modal show={this.state.show_reviews} size="lg" onHide={()=>{this.setState({'show_reviews' : false})}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Reviews</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{'maxHeight' : "80vh", 'overflow' : 'auto'}}>
                        {this.state.product.product_ratings.map((review)=>{
                            return(
                                <div onClick={()=>{this.setState({'show_review_images' : true})}} className="my-3 p-2" style={{"border" : "1px solid #EDEDED"}}>
                                    <div className="justify-content-start d-flex align-items-center flex-row">
                                        <i className="icon-user"></i>
                                        <div className="mx-2">{review.name}</div>
                                    </div>

                                    <div className="justify-content-between d-flex align-items-center flex-row">
                                        <small style={{"color" : 'gray'}}>Reviewd on : {moment(review.created_at).format('lll')}</small>
                                        <div style={{'border' : "1px solid #EDEDED"}} className="px-2 py-1">{review.rating} <i style={{'color' : "gold"}} className="fa fa-star"></i></div>
                                    </div>
                                    
                                    <div style={{'fontSize' : "samll"}} className="mt-2">{review.review}</div>

                                    <div className="row">
                                        {review.review_rating_images.map(image =>{
                                            return(
                                                <div onClick={()=>{this.showReviewImages(review.review_rating_images)}} className="col-xl-2 col-lg-3 col-md-3 col-3 justify-content-center d-flex align-items-center">
                                                    <img src={process.env.REACT_APP_URL + image.image} style={{'height' : '100px', 'objectFit' : 'cover'}}/>
                                                </div>
                                            )
                                        })}
                                       
                                    </div>
                                </div>
                            )
                        })}                       
                    </Modal.Body>
                </Modal>


                <Modal show={this.state.show_gallery} size="lg" onHide={()=>{this.setState({'show_gallery' : false})}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Customer Photos</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{'maxHeight' : "80vh", 'overflow' : 'auto'}}>
                        <div className="row">
                            {this.state.product.review_images.map((image)=>{
                                return(
                                    <div className="col-lg-2 col-md-4 col-3 my-2">
                                        <img onClick={()=>{this.showReviewImages(this.state.product.review_images)}} src={process.env.REACT_APP_URL + image.image} style={{'width' : '100px', 'minHeight' : '100px', 'maxHeight' : '100px', 'cursor':'pointer', 'objectFit' : 'cover'}} alt="zoom" />
                                    </div>
                                )
                                
                            })}    
                        </div>
                                         
                    </Modal.Body>
                </Modal>


                {this.state.show_review_images && (
                    <ImageViewer
                        src={ this.state.review_images }
                        disableScroll={ false }
                        closeOnClickOutside={ true }
                        onClose={()=>{this.setState({'show_review_images' : false})}}
                        backgroundStyle={{'background' : "#000000", zIndex : 10000}}
                    />
                )}

                <div className="container padding-bottom-1x mb-1 ">

                    <div className="row">
            
                        <div className="col-xxl-5 col-lg-6 col-md-6">
                            <div className="product-gallery">
                               
                                {this.state.product.product_images.length > 0 &&
                                    <div className="product-thumbnails insize mt-2">
                                        
                                        <OwlCarousel className='owl-theme' dots={true} loop={false} items={1} margin={10}>
                                               
                                               {this.state.product.product_images.map((image, index)=>{
                                                   return(
                                                       <>
                                                           {image.is_video == true &&
                                                                <div className="item d-block" style={{width: '100%'}}>
                                                                    <iframe width="420" height="315"
                                                                            src={image.image}>
                                                                        </iframe>
                                                                </div>
                                                           }
                                                           {image.is_video == false &&
                                                                <div className="item d-block" style={{width: '100%'}}>
                                                                    <img src={process.env.REACT_APP_URL + image.image} alt="zoom" />
                                                                </div>
                                                           }
                                                       </>
                                                   )
                                               })}
                                           
                                           </OwlCarousel>
                                    </div>
                                }
                            </div>
                        </div>

                        
                        <div className="col-xxl-7 col-lg-6 col-md-6">
                            <div className="details-page-top-right-content d-flex flex-column align-items-baseline">
                                <div className="div w-100">
                                    <h4 className="mb-2 p-title-main" style={{'textAlign' : 'left'}}>{this.state.product.name}</h4>
                                    <span className="h3 d-block price-area" style={{'textAlign' : 'left'}}>
                                        <h4 className="product-price justify-content-start d-flex align-items-center">
                                            {this.state.product.discount == 0 &&
                                                <>
                                                    ₹ {(this.state.product.price - (this.state.product.price * this.state.product.discount)/100).toFixed(0)}
                                                </>
                                            }
                                            {this.state.product.discount != 0 &&
                                                <>
                                                    <del>₹ {this.state.product.price}</del>
                                                    <span class="bg-success mx-2 px-2" style={{'color' : '#FFFFFF', fontSize : '15px', borderRadius : 50}}>{this.state.product.discount} % OFF</span>
                                                    ₹ {(this.state.product.price - (this.state.product.price * this.state.product.discount)/100).toFixed(0)}
                                                </>
                                            }
                                                    
                                        </h4>  
                                    </span>

                                    <div style={{'color' : '#00A86B', width:'100%', textAlign:'left', marginTop : '2%', 'marginBottom' : '2%'}}>Estimated delivery 3 to 7 days</div>
                                    
                                    <div className="row align-items-baseline pb-4">
                                        {this.state.product.product_colors.length > 0 &&
                                            <div class="col-sm-4">
                                                <div class="form-group">
                                                    <label for="Color" style={{'textAlign' : 'left', width: '100%'}}>Color</label>
                                                    <select class="form-control attribute_option" value={this.state.color} onChange={(event)=>this.setColor(event.target.value)} id="Color">
                                                        <option value="">Select Color</option>  
                                                        {this.state.product.product_colors.map((color)=>{
                                                            return(
                                                                <option value={color}>{color}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        }

                                        {this.state.product.product_sizes.length > 0 &&
                                            <div class="col-sm-4">
                                                <div class="form-group">
                                                    <label for="Size" style={{'textAlign' : 'left',width: '100%'}}>Size</label>
                                                    <select class="form-control attribute_option" value={this.state.size} onChange={(event)=>this.setSize(event.target.value)} id="Size">
                                                        <option value="">Select Size</option>  
                                                        {this.state.product.product_sizes.map((size)=>{
                                                            return(
                                                                <option value={size}>{size}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="col-sm-12">
                                        <div className="p-action-button justify-content-between d-flex align-items-end flex-row">
                                            {this.state.product.quantity > 0 &&
                                                <button className="btn btn-primary m-0 a-t-c-mr" onClick={()=>{this.addToCart()}}>
                                                    <i className="icon-bag"></i>
                                                    <span>Add to Cart</span>
                                                </button>
                                            }

                                            {this.state.product.quantity == 0 &&
                                                <div className="py-3 px-4" style={{'color':'red', background:'#EDEDED', position : 'relative', textAlign : 'center'}}> Out Of Stock </div>
                                            }
                                            

                                            <div className="justify-content-end d-flex align-items-start flex-column">
                                                <span className="text-muted mr-1 justify-content-center d-flex align-items-start flex-row">Share: </span>
                                                <div className="justify-content-center d-flex align-itams-start flex-row">
                                                    <FacebookShareButton url={this.share_url + this.state.product.id} className="mx-2">
                                                        <FacebookIcon size={32} round  bgStyle={{fill:'#FF6A00'}} />
                                                    </FacebookShareButton>

                                                    <TwitterShareButton url={this.shareUrl} className="mx-2"  title={this.title}>
                                                        <XIcon size={32} round  bgStyle={{fill:'#FF6A00'}} />
                                                    </TwitterShareButton>

                                                    <EmailShareButton url={this.shareUrl} className="mx-2"  subject={this.title} body="body">
                                                        <EmailIcon size={32} round  bgStyle={{fill:'#FF6A00'}} />
                                                    </EmailShareButton>

                                                    <WhatsappShareButton url={this.share_url + this.state.product.id} className="mx-2"  title={this.state.product.name} separator=":: ">
                                                        <WhatsappIcon size={32} round  bgStyle={{fill:'#FF6A00'}} />
                                                    </WhatsappShareButton>
                                                </div>
                                                

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <section className="my-5 w-100">
                                    <Accordion>
                                        <Accordion.Item className="my-3" eventKey="0">
                                            <Accordion.Header>Description</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="justify-content-start d-flex align-items-baseline flex-column" style={{'textAlign' : 'start'}}>
                                                        {parse(this.state.product.description)}
                                                    </div>
                                                </Accordion.Body>
                                        </Accordion.Item>
                                        {this.state.product.cancellations_returns &&
                                            <Accordion.Item className="my-3" style={{'border' : '1px solid rgba(0,0,0,.125)'}}  eventKey="1">
                                                <Accordion.Header>Cancellations & Returns</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="justify-content-start d-flex align-items-baseline flex-column" style={{'textAlign' : 'start'}}>
                                                            {parse(this.state.product.cancellations_returns)}
                                                        </div>
                                                    </Accordion.Body>
                                            </Accordion.Item>
                                        }

                                        {this.state.product.stylist_notes &&
                                            <Accordion.Item className="my-3" style={{'border' : '1px solid rgba(0,0,0,.125)'}}  eventKey="2">
                                                <Accordion.Header>Stylist Notes</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="justify-content-start d-flex align-items-baseline flex-column" style={{'textAlign' : 'start'}}>
                                                            {parse(this.state.product.stylist_notes)}
                                                        </div>
                                                    </Accordion.Body>
                                            </Accordion.Item>
                                        }
                                    </Accordion>
                                    
                                    {this.state.product.product_ratings.length > 0 &&
                                        <Accordion defaultActiveKey={"0"}>
                                            <Accordion.Item className="my-3" eventKey="0">
                                                <Accordion.Header>Reviews</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="justify-content-center d-flex align-items-center flex-column">
                                                            
                                                            <div className="row" style={{'width' : '100%'}}>
                                                                <div className="col-4 px-0 justify-content-center d-flex align-items-center flex-column">
                                                                    <span style={{'fontSize' : '35px'}}>{this.state.product.rating} <i style={{color : '#14958f'}} className="fa fa-star"></i></span>   
                                                                    <div>{this.state.product.total_ratings} Verified Buyers</div>
                                                                </div>
                                                                {/* <div className="my-4" style={{"border" : "1px solid #d4d5d9"}}></div> */}

                                                                <div className="col-8" style={{'justifyContent' : 'center', 'display' : 'flex', 'alignItems' : 'center', 'flexDirection' : 'column'}}>
                                                                    {this.state.product.rating_by_group.map((rating)=>{
                                                                        return(
                                                                            <div style={{'justifyContent' : 'center', 'display' : 'flex', 'alignItems' : 'center', 'flexDirection' : 'row', 'marginVertical' : '0.5%', 'width' : '100%'}}>
                                                                                <div style={{fontWeight:'500', 'fontWeight':"bold", paddingRight:'1%'}}>{rating.rating}</div>
                                                                                <i className='fas fa-star'  color='#000000' style={{'fontSize' : '13px', marginLeft : '5px'}} />
                                                                                
                                                                                {rating.rating == 5 &&
                                                                                    <ProgressBar style={{'width' : '50%', height : '6px', marginLeft : '10px'}} variant='success' now={rating.total_rating * 100 / this.state.product.total_ratings } />
                                                                                }

                                                                                {rating.rating == 4 &&
                                                                                    <ProgressBar style={{'width' : '50%', height : '6px', marginLeft : '10px'}} variant='success' now={rating.total_rating * 100 / this.state.product.total_ratings} />
                                                                                }

                                                                                {rating.rating == 3 &&
                                                                                    <ProgressBar style={{'width' : '50%', height : '6px', marginLeft : '10px'}} variant='success' now={rating.total_rating * 100 / this.state.product.total_ratings} />
                                                                                }

                                                                                {rating.rating == 2 &&
                                                                                    <ProgressBar style={{'width' : '50%', height : '6px', marginLeft : '10px'}} variant='success' now={rating.total_rating * 100 / this.state.product.total_ratings} />
                                                                                }

                                                                                {rating.rating == 1 &&
                                                                                    <ProgressBar style={{'width' : '50%', height : '6px', marginLeft : '10px'}} variant='success' now={rating.total_rating * 100 / this.state.product.total_ratings} />
                                                                                }
                                                                                
                                                                                <div style={{fontWeight:'500',  marginLeft :'5%'}}>{rating.total_rating}</div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>

                                                            {this.state.product.review_images.length > 0 &&
                                                                <>
                                                                    <b className="mt-4" style={{'textAlign' : 'left', 'fontWeight':'600',  'fontSize':'16px', 'width' : '100%'}}>Customer Photos ({this.state.product.review_images.length})</b>
                                                                    <div className="row w-100 mt-4">
                                                                        {this.state.product.review_images.slice(0, 4).map((image, index)=>{
                                                                            return(
                                                                                <div className="col-4">
                                                                                    {index < 2 &&
                                                                                        <img onClick={()=>{this.showReviewImages(this.state.product.review_images)}} src={process.env.REACT_APP_URL + image.image} style={{'width' : '100px', 'cursor':'pointer', 'height' : '100px', 'objectFit' : 'cover'}} alt="zoom" />
                                                                                    }
                                                                                    {index == 2 &&
                                                                                        <div style={{'position' : 'relative'}}>
                                                                                            <img onClick={()=>{this.setState({'show_gallery' : true})}} src={process.env.REACT_APP_URL + image.image} style={{'width' : '100px', 'cursor':'pointer', 'filter': 'blur(4px)', 'height' : '100px', 'objectFit' : 'fill'}} alt="zoom" />

                                                                                            <b onClick={()=>{this.setState({'show_gallery' : true})}} style={{'position' : 'absolute', 'fontSize' : '30px', 'fontWeight' : '500', color : '#000000', 'alignItems' : 'center', 'top':'30%',  'cursor':'pointer', 'right':0, 'left':0}}>+ {this.state.product.review_images.length - index}</b>
                                                                                        </div>
                                                                                        
                                                                                    }
                                                                                    
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </>  
                                                            }

                                                            <div className="my-4" style={{'width' : '100%', "border" : "1px solid #d4d5d9"}}></div>

                                                            <b style={{'textAlign' : 'left', 'fontWeight':'600',  'fontSize':'16px', 'width' : '100%'}}>Customer Reviews ({this.state.product.product_ratings.length})</b>
                                                            
                                                            {this.state.product.product_ratings.length > 0 &&
                                                                <>  
                                                                    <div className="w-100 mt-4" style={{'justifyContent' : 'flex-start', 'display' : 'flex', 'alignItems' : 'center', 'flexDirection' : "row"}}>
                                                                        <i className="fas fa-user-circle mx-2" style={{'fontSize' : '40px'}}></i>
                                                                        <div className="mx-2">{this.state.product.product_ratings[0].name}</div>
                                                                        <div style={{'fontSize' : '20px'}}>{this.state.product.product_ratings[0].rating}</div>
                                                                        <i style={{color : '#14958f'}} className="fa fa-star"></i>
                                                                        <div className="mx-2"> | </div>
                                                                        <div>{moment(this.state.product.product_ratings[0].created_at).format('lll')} </div>
                                                                    </div>
                                                                    <div className="w-100 mt-2" style={{'justifyContent' : 'flex-start', 'display' : 'flex', 'alignItems' : 'center', 'flexDirection' : "row"}}>{this.state.product.product_ratings[0].review}</div>

                                                                    <div className="mt-3" onClick={()=>{this.setState({'show_reviews' : true})}} style={{'textAlign' : 'left', cursor : 'pointer', color:'red', 'fontWeight':'500',  'fontSize':'16px', 'width' : '100%'}}>View All {this.state.product.product_ratings.length} Reviews {"\>"}</div>
                                                                </>
                                                            }

                                                        </div>
                                                    </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    }
                                </section>
                            </div>
                        </div>
                    </div>


                    {this.state.similar_products.length > 0 &&
                        <div className="deal-of-day-section mt-20">
                            <div className="container mt-5 pt-5">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title d-flex">
                                            <h2 className="h3">Similar Products</h2>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="row g-3">
                                    {this.state.similar_products.slice(0, 6).map((product, index)=>{
                                        return(
                                            <Link to={`/product/product-details/${product.id}`} className="col-xxl-3 col-lg-3 col-md-6 col-6">
                                                <div className="product-card">
                                                    <div className="product-thumb">
                                                        {/* {product.discount != 0 &&
                                                            <span class="product-badge bg-success">{product.discount} % OFF</span>
                                                        } */}
                                                        
                                                        <img className="lazy" src={process.env.REACT_APP_URL + product.image}  alt="Product"/>
                                                    </div>

                                                    <div className="product-card-body">
                                                        <h3 className="product-title">
                                                            {product.name}
                                                        </h3>
                                                        <h4 className="product-price">
                                                            {product.discount == 0 &&
                                                                <>
                                                                    ₹ {(product.price - (product.price * product.discount)/100).toFixed(0)}
                                                                </>
                                                            }
                                                            {product.discount != 0 &&
                                                                <>
                                                                    <del>₹ {product.price}</del>
                                                                    <span class="bg-success mx-2 px-2" style={{'color' : '#FFFFFF', borderRadius : 50}}>{product.discount} % OFF</span>
                                                                    ₹ {(product.price - (product.price * product.discount)/100).toFixed(0)}
                                                                </>
                                                            }
                                                            
                                                        </h4>

                                                    </div>

                                                </div>
                                            </Link>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    }

}


export default  withRouter(ProductDetails);