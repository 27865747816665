/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


class Brands extends React.Component {

    constructor() {
        super();
        this.state = {};
    }


    render() {
        return (
            <>
                <div class="page-title">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <ul class="breadcrumbs">
                                    <li><a href="index.html">Home</a> </li>
                                    <li class="separator">&nbsp;</li>
                                    <li>Brand</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container pt-0 pb-5">
                    <div class="row g-3">
                        <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-4 col-6">
                            <a class="b-p-s-b" href="catalog4c54.html?brand=Adidas">
                                <img class="d-block hi-50" src="assets/images/1632336527add.png" alt="Adidas" title="Adidas"/>
                            </a>
                        </div>
                        <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-4 col-6">
                            <a class="b-p-s-b" href="catalogd1fb.html?brand=Lavie">
                                <img class="d-block hi-50" src="assets/images/1632336517leves.jpg" alt="Lavie" title="Lavie"/>
                            </a>
                        </div>
                        <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-4 col-6">
                            <a class="b-p-s-b" href="catalog6e7b.html?brand=Skyart">
                                <img class="d-block hi-50" src="assets/images/1632336538skyart.png" alt="Skyart" title="Skyart"/>
                            </a>
                        </div>
                        <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-4 col-6">
                            <a class="b-p-s-b" href="cataloge327.html?brand=Nike">
                                <img class="d-block hi-50" src="assets/images/1632336489nike.jpg" alt="Nike" title="Nike"/>
                            </a>
                        </div>
                        <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-4 col-6">
                            <a class="b-p-s-b" href="catalog4ad2.html?brand=Samsung">
                                <img class="d-block hi-50" src="assets/images/1632336479samsung.png" alt="Samsung" title="Samsung"/>
                            </a>
                        </div>
                        <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-4 col-6">
                            <a class="b-p-s-b" href="catalog33dc.html?brand=Yamaha">
                                <img class="d-block hi-50" src="assets/images/1632336551yamaha.png" alt="Yamaha" title="Yamaha"/>
                            </a>
                        </div>
                        <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-4 col-6">
                            <a class="b-p-s-b" href="catalogd4ed.html?brand=HM">
                                <img class="d-block hi-50" src="assets/images/1632336576hm.jpg" alt="H.M" title="H.M"/>
                            </a>
                        </div>
                        <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-4 col-6">
                            <a class="b-p-s-b" href="catalog99f3.html?brand=Loreal">
                                <img class="d-block hi-50" src="assets/images/1632336591lora.jpg" alt="Loreal" title="Loreal"/>
                            </a>
                        </div>
                        <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-4 col-6">
                            <a class="b-p-s-b" href="catalog4b37.html?brand=Ascis">
                                <img class="d-block hi-50" src="assets/images/1632336642ascis.jpg" alt="Ascis" title="Ascis"/>
                            </a>
                        </div>
                    </div>
                </div>

                <a class="announcement-banner" href="#announcement-modal"></a>
                <div id="announcement-modal" class="mfp-hide white-popup">
                    <div class="announcement-with-content">
                        <div class="left-area">
                            <img src="assets/images/1638791990Untitled-1.jpg" alt=""/>
                        </div>
                        <div class="right-area">
                            <h3 class="">Get 50% Discount.</h3>
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, facere nesciunt doloremque
                                nobis debitis sint?</p>
                            <form class="subscriber-form" action="https://pavilioninfra.com/e-commerce1/subscriber/submit"
                                method="post">
                                <input type="hidden" name="_token" value="plOfH42AJ7cwMkOlDwfooXCyKOhT3Mm3ngAHU41P"/>
                                <div class="input-group">
                                    <input class="form-control" type="email" name="email" placeholder="Your e-mail"/>
                                    <span class="input-group-addon"><i class="icon-mail"></i></span>
                                </div>
                                <div aria-hidden="true">
                                    <input type="hidden" name="b_c7103e2c981361a6639545bd5_1194bb7544" tabindex="-1"/>
                                </div>

                                <button class="btn btn-primary btn-block mt-2" type="submit">
                                    <span>Subscribe</span>
                                </button>
                            </form>
                        </div>
                    </div>


                </div>
            </>
        )
    }
}

export default Brands;