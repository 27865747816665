import React from 'react'
import { Link } from 'react-router-dom';
import Api from '../../Services/api';
import { toast } from 'react-toastify';
import parse from 'html-react-parser';
import Accordion from 'react-bootstrap/Accordion';

class Faqs extends React.Component {


    componentDidMount  =async()=>{
        this.getFaqs();
    }


    constructor() {
        super();
        this.state = {
            'faqs' : []
        };
    }



    getFaqs = async()=>{
        var response = await Api.GetRequest({}, 'faqs');
        if(response.status == 200){
            console.log(response)
            this.setState({'faqs' : response.faqs});
        }
        else{
            toast.error("Something went wrong. Please try again later.")
        }
    }


    render() {
        return (
            <>
                <div class="container padding-bottom-1x my-3">
                    {this.state.faqs.map((faq, index)=>{
                        return(
                            <Accordion defaultActiveKey={0}>
                                <Accordion.Item eventKey={index}>
                                    <Accordion.Header>{faq.title}</Accordion.Header>
                                    <Accordion.Body>
                                        <div class="card-body justify-content-start d-flex align-items-baseline flex-column" style={{'textAlign' : 'start'}}>
                                            {parse(faq.description)}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        )
                    })}
                </div>
            </>
        )
    }
}

export default Faqs;