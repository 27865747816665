import React from 'react'
import { Link } from 'react-router-dom';
import Api from '../../Services/api';
import { toast } from 'react-toastify';
import withRouter from '../../Services/withRouter';
import parse from 'html-react-parser';

class Pages extends React.Component {


    componentDidMount  =async()=>{
        let slug = this.props.params.slug;
        console.log(slug)
        if(slug){
            await this.setState({'slug' : slug});
            window.scroll(0, 0);
            this.getPageDetails();
        }
        else{

        }
    }


    componentDidUpdate = async(prevProps, prevState)=>{
        if(this.props.params.slug != this.state.slug){
            await this.setState({'slug' : prevProps.params.slug});
            
            this.getPageDetails();
        }
    }




    constructor() {
        super();
        this.state = {
            'page' : {
                'description' : ""
            },
            'slug' : ""
        };
    }



    getPageDetails = async()=>{
        var params = {
            'slug' : this.state.slug
        }
        var response = await Api.GetRequest(params, 'page');
        if(response.status == 200){
            this.setState({'page' : response.page});
            window.scroll(0, 0);
        }
        else{
            toast.error("Something went wrong. Please try again later.");
        }
    }


    render() {
        return (
            <>
                <div className="pt-5 pb-5 ">
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12 mb-4 mt-4">
                                <div className="card">
                                    <div className="card-body px-4 py-5">
                                        <div className="d-page-content">
                                            <h4 className="d-block text-center"><b>{this.state.page.title}</b></h4>
                                            <div className="justify-content-start d-flex align-items-baseline flex-column" style={{'textAlign' : 'start'}}>
                                                {parse(this.state.page.description)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(Pages);