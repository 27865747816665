/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom';
import Api from '../Services/api';

class Footer extends React.Component {


    componentDidMount=async()=>{
        this.getPages();
    }

    constructor() {
        super();
        this.state = {
            'pages' : []
        };
    }



    getPages = async()=>{
        var response = await Api.GetRequest({}, 'pages');
        if(response.status == 200){
            this.setState({'pages' : response.pages});
        }
        else{

        }
    }


    render() {
        return (
            <>
                 <footer className="site-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-12">
                                <h3 className="widget-title" style={{'textAlign' : 'left'}}>Social Links</h3>
                                <div className="footer-social-links">
                                    <Link to={process.env.REACT_APP_INSTAGRAM_LINK}><span><i className="fab fa-instagram"></i></span></Link>
                                    <Link to={process.env.REACT_APP_PINTEREST_LINK}><span><i className="fab fa-pinterest"></i></span></Link>
                                    <Link to={process.env.REACT_APP_YOUTUBE_LINK}><span><i className="fab fa-youtube"></i></span></Link>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                              
                                <div className="widget widget-links widget-light-skin">
                                    <h3 className="widget-title" style={{'textAlign' : 'left'}}>Usefull Links</h3>
                                    <ul>
                                        {this.state.pages.map((page)=>{
                                            return(
                                                <li className='d-flex'>
                                                    <Link className="" to={page.slug}>{page.title}</Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                
                                <section className="widget widget-light-skin">
                                    <>
                                        <h3 className="widget-title" style={{'textAlign' : 'left'}}>Get In Touch</h3>
                                        <p className="mb-1" style={{'textAlign' : 'left'}}><strong>Phone: </strong> {process.env.REACT_APP_CALL}</p>
                                        <p className="mb-3" style={{'textAlign' : 'left'}}><strong>Email: </strong> {process.env.REACT_APP_EMAIL}</p>
                                        <ul className="list-unstyled text-sm" style={{'textAlign' : 'left'}}>
                                            <li><span className=""><strong>Monday-Saturday: </strong></span>9:00 AM - 6:00 PM</li>
                                        </ul>
                                    </>
                                    
                                    
                                </section>
                            </div>
                            
                            
                        </div>
                        
                        <p className="footer-copyright"> 18STORES © All rights reserved.</p>
                    </div>
                </footer>

                <a className="scroll-to-top-btn" href="#">
                    <i className="icon-chevron-up"></i>
                </a>
                <div className="site-backdrop"></div>
                {/* <div className="js-cookie-consent cookie-consent">
    
                    <div className="container">
                        <div className="cookie-container">
                            <span className="cookie-consent__message">
                                Your experience on this site will be improved by allowing cookies.
                            </span>
            
                            <button className="btn btn-info js-cookie-consent-agree cookie-consent__agree">
                                Allow Cookies
                            </button>
                        </div>
                    </div>
            
                </div> */}
            </>        
        )
    }
}

export default Footer