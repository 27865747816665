/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Api from '.././../Services/api';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPlayer from 'react-player';
import {Img} from 'react-image';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

class Home extends React.Component {


    componentDidMount = async () => {
        await this.getBanners();
        await this.getBlogs();
        await this.getVideo();  
        await this.getCategories();
        await this.getOffers();
        
        await this.getLatestArrivals();
        await this.getPopularProducts();
        await this.getPromotions();
        await this.getTestimonials();
    }


    constructor(props) {
        super(props);
        this.state = {
            'banners' : [],
            'offers' : [],
            'promotions' : [],
            'testimonials' : [],
            'blogs' : [],
            'video' : "",
            'latest_arrivals' : [],
            'popular_products' : [],
            'categories' : []
        };
    }


    getBanners = async()=>{
        var response = await Api.GetRequest({}, 'banners');
        if(response.status == 200){
            this.setState({'banners' : response.banners})
        }
        else{
            toast.error("Something went wrong. Please try again later.");
        }
    }


    getBlogs = async () => {
        var response = await Api.GetRequest({}, 'blogs');
        if (response.status == 200) {
            this.setState({'blogs': response.blogs});
        }
        else {
            toast.error("Something went wrong. Please try again later.");
        }
    }


    getVideo = async () => {
        var response = await Api.GetRequest({}, 'video');
        if (response.status == 200) {
            this.setState({'video': response.video});
        }
        else {
            toast.error("Something went wrong. Please try again later.");
        }
    }



    getCategories = async () => {
        var response = await Api.GetRequest({}, 'categories');
        if (response.status == 200) {
            this.setState({'categories': response.categories });
        }
        else {
            toast.error("Something went wrong. Please try again later.");
        }
    }


    getOffers = async () => {
        var response = await Api.GetRequest({}, 'offers');
        if (response.status == 200) {
            this.setState({'offers': response.offers });
        }
        else {
            toast.error("Something went wrong. Please try again later.");
        }
    }


    getLatestArrivals = async()=>{
        var response = await Api.GetRequest({}, 'products/latest-arrivals');
        if(response.status == 200){
            this.setState({'latest_arrivals' : response.latest_arrivals})
        }
        else{
            toast.error("Something went wrong. Please try again later.");
        }
    }



    getPopularProducts = async()=>{
        var response = await Api.GetRequest({}, 'products/popular');
        if(response.status == 200){
            this.setState({'popular_products' : response.popular_products})
        }
        else{
            toast.error("Something went wrong. Please try again later.");
        }
    }


    getPromotions = async () => {
        var response = await Api.GetRequest({}, 'promotions');
        if (response.status == 200) {
            this.setState({ 'promotions': response.promotions });
        }
        else {
            toast.error("Something went wrong. Please try again later.");
        }
    }
    
    getTestimonials = async () => {
        var response = await Api.GetRequest({}, 'testimonials');
        if (response.status == 200) {
            this.setState({ 'testimonials': response.testimonials });
        }
        else {
            toast.error("Something went wrong. Please try again later.");
        }
    }


    
    render() {
        return (
            <>
                {this.state.banners.length > 0 &&
                    <div className="slider-area-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="hero-slider">
                                        <OwlCarousel className="owl-theme"  loop dots={false} nav={true} items={1}>
                                            
                                            {this.state.banners.map((banner)=>{
                                                return(
                                                    <div className="item" style={{"background": "url('"+process.env.REACT_APP_MEDIA_URL + banner.image +"')"}}>
                                                        <div className="item-inner">
                                                            <div className="from-bottom">
                                                                {/* <div className="title text-body">{banner.title}</div>
                                                                <div className="subtitle text-body">{banner.description}</div> */}
                                                            </div>
                                                            {/* <a className="btn btn-primary scale-up delay-1" href="#"> <span>Buy Now</span>
                                                            </a> */}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                
                {this.state.latest_arrivals.length > 0 &&
                    <div className="deal-of-day-section mt-20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title d-flex">
                                        <h2 className="h3">Latest Arrivals</h2>
                                        <div className="right-area">
                                        
                                            <Link  className="right_link" to="/latest-arrivals">View All 
                                                <i className="icon-chevron-right"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            


                            <div className="row g-3">

                                {this.state.latest_arrivals.slice(0, 6).map((product, index)=>{
                                    return(
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6">
                                            <Link to={`/product/product-details/${product.id}`} className="product-card ">
                                                <div className="product-thumb">
                                                    {/* {product.discount != 0 &&
                                                        <span class="product-badge bg-success">{product.discount} % OFF</span>
                                                    } */}
                                                    
                                                    <img className="lazy" src={process.env.REACT_APP_URL + product.image} alt="Product"/>

                                                    {product.total_ratings > 0 &&
                                                        <div className='px-2 py-1' style={{'position' : 'absolute', left:'5%', "backgroundColor": "hsla(0, 0%, 100%, .8)", 'justifyContent' : 'center', 'display' : 'flex', 'alignItems'  :'center', flexDirection :'row', 'bottom' : 10}}>
                                                            <div style={{'color' : '#000000'}}>{product.rating}</div>
                                                            <i style={{'color' : '#000000'}} class="fa fa-star mx-1" aria-hidden="true"></i>
                                                            <b style={{'color' : '#000000'}} className='mx-2'>|</b>
                                                            <div style={{'color' : '#000000'}}>{product.total_ratings}</div>
                                                        </div>
                                                    }
                                                    
                                                </div>
                                                <div className="product-card-body">
                                                    <h3 className="product-title">
                                                        <Link to={`/product/product-details/${product.id}`}>{product.name}</Link>
                                                    </h3>
                                                    <h4 className="product-price d-flex justify-content-center align-items-center">
                                                        {product.discount == 0 &&
                                                            <>
                                                                ₹ {(product.price - (product.price * product.discount)/100).toFixed(0)}
                                                            </>
                                                        }
                                                        {product.discount != 0 &&
                                                            <>
                                                                <del className="main-price">₹ {product.price}</del>
                                                                <span className="bg-success mx-2 px-2 discount-price" style={{'color' : '#FFFFFF', fontSize : '15px', borderRadius : 50}}>{product.discount} % OFF</span>
                                                                <div className="final-price">₹ {(product.price - (product.price * product.discount)/100).toFixed(0)}</div>
                                                            </>
                                                        }
                                                        
                                                    </h4>

                                                </div>
                                            </Link>
                                        </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }


                {this.state.offers.length > 0 &&
                    <div className="slider-area-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="hero-slider">
                                        <OwlCarousel className="owl-theme"  loop dots={false} nav={true} items={1}>
                                            
                                            {this.state.offers.map((offer)=>{
                                                return(
                                                    <div className="item" style={{"background": "url('"+process.env.REACT_APP_MEDIA_URL + offer.image +"')"}}>
                                                        <div className="item-inner">
                                                            <div className="from-bottom">
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                )
                                            })}
                                        </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {this.state.popular_products.length > 0 &&
                    <div className="deal-of-day-section mt-20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title d-flex">
                                        <h2 className="h3">Popular Products</h2>
                                        <div className="right-area">
                                            <Link  className="right_link" to="/populars">View All 
                                                <i className="icon-chevron-right"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3">
                                {this.state.popular_products.slice(0, 6).map((product, index)=>{
                                    return(
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6">
                                            <Link to={`/product/product-details/${product.id}`} className="product-card">
                                                <div className="product-thumb">
                                                    {/* {product.discount != 0 &&
                                                        <span class="product-badge bg-success">{product.discount} % OFF</span>
                                                    }
                                                     */}
                                                    <img className="lazy" src={process.env.REACT_APP_URL + product.image}  alt="Product"/>
                                                    {product.total_ratings > 0 &&
                                                        <div className='px-2 py-1' style={{'position' : 'absolute', left:'5%', "backgroundColor": "hsla(0, 0%, 100%, .8)", 'justifyContent' : 'center', 'display' : 'flex', 'alignItems'  :'center', flexDirection :'row', 'bottom' : 10}}>
                                                            <div style={{'color' : '#000000'}}>{product.rating}</div>
                                                            <i style={{'color' : '#000000'}} class="fa fa-star mx-1" aria-hidden="true"></i>
                                                            <b style={{'color' : '#000000'}} className='mx-2'>|</b>
                                                            <div style={{'color' : '#000000'}}>{product.total_ratings}</div>
                                                        </div>
                                                    }
                                                    
                                                </div>

                                                <div className="product-card-body">
                                                    <h3 className="product-title">
                                                        <Link to={`/product/product-details/${product.id}`}>{product.name}</Link>
                                                    </h3>
                                                    <h4 className="product-price d-flex justify-content-center align-items-center">
                                                        {product.discount == 0 &&
                                                            <>
                                                                ₹ {(product.price - (product.price * product.discount)/100).toFixed(0)}
                                                            </>
                                                        }
                                                        {product.discount != 0 &&
                                                            <>
                                                                <del className="main-price">₹ {product.price}</del>
                                                                <span className="bg-success mx-2 px-2 discount-price" style={{'color' : '#FFFFFF', fontSize : '15px', borderRadius : 50}}>{product.discount} % OFF</span>
                                                                <div className="final-price">₹ {(product.price - (product.price * product.discount)/100).toFixed(0)}</div>
                                                            </>
                                                        }
                                                    </h4>

                                                </div>

                                            </Link>
                                        </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }


                {this.state.promotions.length > 0 &&
                    <div className="slider-area-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="hero-slider">
                                        <OwlCarousel className="owl-theme"  loop dots={false} nav={true} items={1}>
                                            
                                            {this.state.promotions.map((promotion)=>{
                                                return(
                                                    // <div className="item" style={{"background": "url('"+process.env.REACT_APP_MEDIA_URL + promotion.image +"')"}}>
                                                    //     <div className="item-inner">
                                                            
                                                    //     </div>
                                                    // </div>

                                                    <div className="item" style={{'padding':'0px'}}>
                                                        <img  src={process.env.REACT_APP_MEDIA_URL + promotion.image}
                                                            // loader={<Skeleton style={{'width' : '100vw', height : '100vh'}} />}
                                                            className="lazy"
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="slider-area-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <ReactPlayer controls url={process.env.REACT_APP_MEDIA_URL + this.state.video} width={'100%'} height={'auto'}/>
                            </div>
                        </div>
                    </div>
                </div>


                {this.state.testimonials.length > 0 &&
                    <div className="deal-of-day-section mt-20 mb-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title">
                                        <h2 className="h3">Testimonials</h2>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3">

                                <div className="col-lg-12">
                                    <OwlCarousel className="popular-category-slide owl-theme"  loop dots={false} nav={true} margin={10} items={1}
                                        
                                    >
                                        {this.state.testimonials.map((testimonial, index)=>{
                                            return(
                                                <div className="slider-item">
                                                    <div className="product-card test p-2" style={{'backgroundImage' : `url("assets/images/testimonial.png")`, 'backgroundPosition': 'center', 'backgroundRepeat': 'no-repeat', 'backgroundSize': 'cover'}}>
                                                       
                                                        <div className="product-card-body m-1 py-5 testimonial-margin" style={{'background': 'rgba(255, 255, 255, 0.3)', backdropFilter: 'blur(6.4px)'}}>
                                                            <div className="product-thumb justify-content-center d-flex flex-column align-items-center p-5" style={{'backgroundColor' : '#3f434c', overflow:'visible'}}>
                                                                <div style={{'background' : '#FFFFFF', "top": "-7%", "position": "absolute", 'width': '78%', fontSize:'x-large', 'fontStyle':'italic', 'fontFamily' : 'Font Awesome 5 Pro'}} className='p-3' >
                                                                      Testimonials  
                                                                </div>
                                                                <div className='justify-content-center d-flex align-items-center flex-row pt-4'>
                                                                    <i className='fa fa-star mx-1' style={{'color' : 'gold'}}></i>
                                                                    <i className='fa fa-star mx-1' style={{'color' : 'gold'}}></i>
                                                                    <i className='fa fa-star mx-1' style={{'color' : 'gold'}}></i>
                                                                    <i className='fa fa-star mx-1' style={{'color' : 'gold'}}></i>
                                                                    <i className='fa fa-star mx-1' style={{'color' : 'gold'}}></i>
                                                                </div>
                                                                <div className='py-5 testimonial-padding'>

                                                                    <p style={{'color' : '#FFFFFF', 'textAlign' : 'start'}}>"{testimonial.description}"</p>
                                                                    <h4 className="product-title mb-0" style={{'textAlign' : 'end', "width":"100%", color:'#FFFFFF', "fontWeight" : 'bold'}}>
                                                                        - {testimonial.name}
                                                                    </h4>
                                                                </div>
                                                                
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </OwlCarousel>
                                </div>

                            </div>
                        </div>
                    </div>
                }

            </>
        )
    }
}

export default Home;